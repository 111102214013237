const QS = require('query-string')
export default function parseGoogleQuery (str) {
  const parse = function (str, prop = 'cd_min') {
    // "cdr:1,cd_min:5/1/2021,cd_max:"
    if (!str) return ''
    str = str.replaceAll(':', '=').replaceAll(',', '&')
    const obj = QS.parse(str)
    return obj[prop] ? obj[prop] : ''
  }

  const qs = JSON.parse(str)
  if (!qs) {
    return ''
  }
  const min = parse(qs['tbs'], 'cd_min')
  const max = parse(qs['tbs'], 'cd_max')
  const range = parse(qs['tbs'], 'qdr')
  let source = qs['tbm'] ? 'Google ' : ''
  switch (qs['tbm']) {
  case 'isch':
    source += 'Images'
    break
  case 'nws':
    source += 'News'
    break
  case 'vid':
    source += 'Videos'
    break
  default:
    source += 'Search'
    break
  }
  let period = ''
  if (min && max) {
    period = `from ${min} to ${max}`
  }
  if (min && !max) {
    period = `since ${min}`
  }
  if (!min && max) {
    period = `older than ${max}`
  }
  if (range) {
    switch (range) {
    case 'd':
      period = 'past 24 hours'
      break
    case 'w':
      period = 'past week'
      break
    case 'm':
      period = 'past month'
      break
    case 'y':
      period = 'past year'
      break
    }
  }
  return `${source? source+': ':''} ${qs.q} ${period? '('+period+')':''}`
}
