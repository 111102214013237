<template>
  <div>
    <SiteNav />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-10 col-8 col-mx-auto">
          <div class="section-spacer"></div>
          <div class="columns">
            <div class="column col-lg-12 col-6 p-centered">
              <div v-if="initial">
                <p>Please enter your username to recover your password.</p>
              </div>
              <div v-if="checkEmail">
                <p>Check your email for further instructions.</p>
              </div>
              <div v-if="askForCode">
                <p>Enter a new password.</p>
                <p>IMPORTANT: The password must be at least 8 characters long and include upper and lowercase letters, numbers and symbols.</p>
              </div>
              <div class="text-center">
                <div class="label label-error mb-2" v-if="error != null">{{ error }}</div>
                <form v-if="initial" @submit.prevent="reset">
                  <div class="form-group">
                    <input id="username" type="text" class="form-input" v-model="username" placeholder="username" required>
                  </div>
                  <div class="center-align">
                    <button class="btn btn-sm col-12" :class="{loading:isWorking}">Reset</button>
                  </div>
                </form>
                <form v-if="askForCode" @submit.prevent="verifyCode">
                  <div class="form-group">
                    <input id="username" type="text" class="form-input" v-model="username" placeholder="username" disabled="disabled">
                  </div>
                  <div class="form-group">
                    <input id="resetCode" type="text" class="form-input" v-model="ownCode" placeholder="confirmation code" autocomplete="off" required disabled="disabled">
                  </div>
                  <div class="form-group">
                    <input id="password" type="password" class="form-input" v-model="pass" placeholder="new password" autocomplete="off" required>
                  </div>
                  <div class="center-align">
                    <button class="btn btn-sm col-12" :class="{loading:isWorking}">Verify</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="section-spacer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav.vue'
export default {
  name: 'Reset',
  title: function () {
    return 'Intel for hire :: Reset password'
  },
  components: {
    SiteNav
  },
  props: {
    resetUsername: {
      type: String
    },
    resetCode: {
      type: String
    }
  },
  data: function () {
    return {
      username: this.resetUsername ? this.resetUsername : '',
      pass: '',
      loggedIn: false,
      error: null,
      isWorking: false,
      initial: true,
      askForCode: false,
      checkEmail: false,
      ownCode: ''
    }
  },
  mounted: function () {
    if (!this.resetUsername || !this.resetCode) {
      return false
    }
    this.initial = false
    this.askForCode = true
    this.ownCode = this.resetCode
  },
  methods: {
    reset: function () {
      const self = this
      this.initial = false
      this.isWorking = true
      this.$cognito.resetPassword(this.username, (err, result) => {
        // console.log('resetPassword', err, result)
        let msg = 'Sorry, something went wrong.'
        if (err && err.code === 'UserNotFoundException') {
          msg = 'I could not find that username.'
        }
        if (err && err.code === 'LimitExceededException') {
          msg = 'You tried too many times, try again later.'
        }
        if (err) {
          self.$gtag.event('recover_error', {
            event_category: 'user',
            event_label: self.username
          })
          self.$router.push({ name: 'Err', params: { message: msg } })
          return false
        }
        self.isWorking = false
        self.checkEmail = true
        // self.askForCode = true
      })
    },
    verifyCode: function () {
      const self = this
      self.isWorking = true
      this.$cognito.confirmPassword(this.username, this.ownCode, this.pass, (err, result) => {
        self.isWorking = false
        if (err) {
          self.$gtag.event('recover_error', {
            event_category: 'user',
            event_label: self.username
          })
          if (err.code && err.code === 'InvalidParameterException') {
            self.error = 'Please follow the instructions to choose a password.'
          } else {
            self.error = 'Something went wrong, contact me to report this.'
          }
          // self.$router.push({ name: 'Err', params: { message: 'Sorry, I could not verify the code.' } })
          self.isWorking = false
          return false
        }
        self.error = ''
        self.$gtag.event('recover_success', {
          event_category: 'user',
          event_label: self.username
        })
        alert('Successfully changed your password! You can log in now.')
        self.$router.push({ path: '/login' })
      })
    }
  }
}

</script>
