<template>
  <div>
    <SiteNav />
    <div class="container">
      <div class="columns">
        <div class="column col-md-8 col-3 p-centered">
          <div class="section-spacer"></div>
          <div class="text-center">
            <div class="label label-success mb-2" v-if="confirmation !== null">{{ confirmation }}</div>
            <div class="label label-error mb-2" v-if="error !== null">{{ error }}</div>
            <form @submit.prevent="login">
              <div class="form-group">
                <input id="username" type="text" class="form-input" v-model="username" placeholder="username" required>
              </div>
              <div class="form-group">
                <input id="password" type="password" class="form-input" v-model="pass" placeholder="password" required>
              </div>
              <div class="center-align">
                <button class="btn btn-sm col-12" :class="{loading:isWorking}">Log in</button>
                <div class="mt-2"></div>
                <p>
                  <small>
                    <router-link :to="{name: 'Register'}">Sign up</router-link>
                    &middot;
                    <router-link :to="{name: 'Reset'}">Forgot?</router-link>
                  </small>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'whatwg-fetch'
// import handleError from '@/services/handle-error'
import SiteNav from '@/components/SiteNav.vue'
export default {
  name: 'Login',
  title: function () {
    return 'Intel for hire :: Login'
  },
  components: {
    SiteNav
  },
  props: {
    confirmUsername: {
      type: String
    },
    confirmCode: {
      type: String
    }
  },
  data: function () {
    return {
      username: this.confirmUsername ? this.confirmUsername : '',
      pass: '',
      confirmation: null,
      error: null,
      loggedIn: false,
      isWorking: false
    }
  },
  beforeCreate: function () {
    document.body.className = 'public-zone'
  },
  mounted: function () {
    const self = this
    if (!this.confirmUsername || !this.confirmCode) {
      return false
    }
    this.$cognito.confirmRegistration(this.confirmUsername, this.confirmCode, (err, result) => {
      if (err) {

        self.confirmation = null
        if (err.code) {
          if (err.code === 'CodeMismatchException') {
            self.error = 'Could not verify. Did you alter the link I gave you?'
          } else if (err.code === 'NotAuthorizedException') {
            self.error = 'It looks like your registration is confirmed already. Just log in.'
          } else {
            self.error = 'Something went wrong. You may contact me to report this.'
          }
        } else {
          self.error = err.message ? err.message : 'Something went wrong.'
        }
        return false
      }
      self.confirmation = 'Awesome. You may log in now.'
    })
  },
  methods: {
    login: function () {
      const self = this
      this.isWorking = true
      // console.log('here in login authenticate', self.$cognito)
      self.$cognito.authenticate(this.username, this.pass, (err, result) => {
        if (err) {
          self.error = err.message
          self.confirmation = null
          self.isWorking = false
          self.$gtag.event('login_error', {
            event_category: 'user',
            event_label: self.username
          })
          return false
          // handleError(new Error(err.message))
        }
        self.$gtag.event('login_success', {
          event_category: 'user',
          event_label: self.username
        })
        // this.loggedIn = true
        // console.log(result)
        // const user = self.$cognito.getUser()
        // this.$uf.userId = self.$cognito.getUserId()
        // this.$uf.username = user ? user.username : ''
        // console.log(self.$eventHub)
        // self.$eventHub.$emit('login')
        const redir = self.$route.query.redirect ? decodeURIComponent(self.$route.query.redirect) : ''
        self.$router.replace(redir ? `${redir}` : `/feed`)
      })
    }
  }
}

</script>
