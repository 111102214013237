import { postService } from '@/services/newpost'

function getJobs (self, options = {}, callback) {
  const reqGetJob = function () {
    return postService(self, options, 'jobs')
      .catch((err) => {
        return callback(err)
      })
      .then((response) => {
        if (!response) {
          return callback(new Error('No response!'))
        }
        if (response.data.length !== 10) { // batches of 10
          self.lastJobEntry = true
        }
        let currentJobIds = []
        let currentActivityIds = []
        response.data.jobs.map(x => {
          let idx = self.jobsIdx.indexOf(x.ID)
          if (idx === -1) {
            self.jobs.push(x)
            self.jobsIdx.push(x.ID)
          } else {
            self.jobs[idx] = x
          }
          currentJobIds.push(x.ID)
        })
        response.data.activities.map(x => {
          let idx = self.activityIdx.indexOf(x.ID)
          if (idx === -1) {
            self.activity.push(x)
            self.activityIdx.push(x.ID)
          } else {
            self.activity[idx] = x
          }
          currentActivityIds.push(x.ID)
        })
        if (!options.ID) {
          let deleteTheseJobs = []
          self.jobs.map(j => {
            if (currentJobIds.indexOf(j.ID) === -1) {
              deleteTheseJobs.push(j.ID)
            }
          })
          deleteTheseJobs.map(id => {
            let idx = self.jobsIdx.indexOf(id)
            self.jobs.splice(idx, 1)
            self.jobsIdx.splice(idx, 1)
          })
          let deleteTheseActivities = []
          self.activity.map(a => {
            if (currentActivityIds.indexOf(a.ID) === -1) {
              deleteTheseActivities.push(a.ID)
            }
          })
          deleteTheseActivities.map(id => {
            let idx = self.activityIdx.indexOf(id)
            self.activity.splice(idx, 1)
            self.activityIdx.splice(idx, 1)
          })
        }
        callback(null)
      })
  }
  reqGetJob()
}

export { getJobs }
