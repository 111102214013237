<template>
  <div>
    <SiteNav :username="username" />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-8 col-8 col-mx-auto text-center">
          <h4>
            My week
          </h4>
          <div class="section-spacer"></div>
          <button @click="changeWeek(-1)" class="btn btn-sm ml-2" :class="{loading: isWorking}">Prior week</button>
          <button @click="setCurrentWeek()" class="btn btn-sm ml-2" :class="{loading: isWorking}">Current week</button>
          <button @click="changeWeek(1)" class="btn btn-sm ml-2" :class="{loading: isWorking}">Next week</button>
          <div class="section-spacer"></div>
          <canvas id="chartEl" width="400" height="200"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav.vue'
import { postService } from '@/services/newpost'
import handleError from '@/services/handle-error'
import moment from 'moment'
export default {
  name: 'Metrics',
  title: function () {
    return 'Intel for hire :: Metrics'
  },
  components: {
    SiteNav
  },
  data: function () {
    return {
      chartData: {
        title: '',
        labels: [],
        datasets: [],
        datasetsIdx: []
      },
      // totalHours: 0,
      totalByProject: {},
      weekNum: moment().isoWeek(),
      // Made not reactive: https://stackoverflow.com/questions/45462535/updating-a-chart-from-chart-js-in-vue
      // chartEl: null,
      isWorking: false
    }
  },
  created: function () {
    const self = this
    this.user = this.$cognito.getUser()
    this.userCognitoId = this.$cognito.getUserId()
    this.username = this.user ? this.user.username : ''
  },
  mounted: function () {
    this.getWeek()
  },
  methods: {
    drawChart: function () {
      const self = this
      let ctx = document.getElementById('chartEl').getContext('2d')
      let bgColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ]
      let borderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ]
      // Made not reactive: https://stackoverflow.com/questions/45462535/updating-a-chart-from-chart-js-in-vue
      let mychart = this.chartEl = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: self.chartData.labels,
          datasets: [], // self.chartData.datasets
          datasetsIdx: []
          /*[{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: bgColors,
            borderColor: borderColors,
            borderWidth: 1
          }]*/
        },
        options: {
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'point',
          },
          plugins: {
            title: {
              display: true,
              text: self.chartData.title
            },
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: true,
              title: {
                display: true,
                text: 'Work hours'
              }
            }
          }
        }
      })
    },
    setCurrentWeek: function () {
      this.weekNum = moment().isoWeek()
      this.getWeek()
    },
    changeWeek: function (chg) {
      if (chg > 0) {
        this.weekNum = (this.weekNum + chg <= 52) ? this.weekNum + chg : this.weekNum
      }
      if (chg < 0) {
        this.weekNum = (this.weekNum + chg >= 0) ? this.weekNum + chg : this.weekNum
      }
      this.getWeek()
    },
    getWeek: function () {
      const self = this
      self.isWorking = true
      const payload = {
        week_num: this.weekNum
      }
      const getHours = function (minutes) {
        return Math.round(minutes / 60 * 10) / 10
      }
      const getISOWeekDates = function (isoWeekNum = 1, year = new Date().getFullYear()) {
        let d = moment().isoWeek(1).startOf('isoWeek').add(isoWeekNum - 1, 'weeks')
        for (var dates = [], i = 0; i < 7; i++) {
          dates.push(d.format('ddd M/D'))
          d.add(1, 'day')
        }
        // console.log(dates)
        return dates
      }
      const emptyChart = function (chart) {
        let num = chart.data.datasets.length
        for (let i = 0; i < num; i++) {
          chart.data.datasets.pop()
          chart.update('hide')
        }
      }
      const reqWeek = function () {
        return postService(self, payload, 'week')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            let bgColors = [ // https://mycolor.space/?hex=%235557D9&sub=1
              '#5557D9',
              '#D149BF',
              '#FF5893',
              '#FF896B',
              '#FFC357',
              '#F9F871'
            ]
            let totalMinutes = 0
            self.totalByProject = {}
            let dataset = {}
            let labels = getISOWeekDates(self.weekNum)
            response.data.map(p => {
              dataset[p.code] = dataset[p.code] ? dataset[p.code] : {}
              totalMinutes += p.minutes
              self.totalByProject[p.code] = self.totalByProject[p.code] ? self.totalByProject[p.code] + p.minutes : p.minutes
              dataset[p.code][p.date] = getHours(p.minutes)
            })
            const data = {}
            let title = `Allocation of ${getHours(totalMinutes)} work hours throughout week of ${labels[0]}, by project`
            labels.map((label, i) => {
              self.chartData.labels[i] = label
            })
            self.chartData.title = title
            if (self.chartEl) {
              self.chartEl.options.plugins.title.text = title
              emptyChart(self.chartEl)
            } else {
              try {
                self.drawChart()
              } catch (e) {
                console.error(e)
              }
            }
            Object.keys(self.totalByProject).map((p, z) => {
              data[p] = new Array(7)
              self.chartData.labels.map((d, i) => {
                data[p][i] = dataset[p][d] || null
              })
              let hours = getHours(self.totalByProject[p])
              let obj = {
                label: `${p} (${getHours(self.totalByProject[p])} hrs)`,
                data: data[p],
                backgroundColor: bgColors[z]
              }
              self.chartEl.data.datasets.push(obj)
              self.chartEl.data.datasetsIdx.push(p)
            })
            try {
              self.chartEl.update()
            } catch (e) {
              console.error(e)
            }
            self.isWorking = false
          })
      }
      reqWeek()
    }
  }
}

</script>
