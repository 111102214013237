<template>
  <div>
    <SiteNav />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-11 col-8 col-mx-auto maxPageWidth text-center">
          <button class="btn btn-sm" @click="$router.push('login')">Login</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav.vue'
export default {
  name: 'Home',
  title: function () {
    return 'Intel for hire App :: Home'
  },
  components: {
    SiteNav
  }
}

</script>
<style lang="css">
.client {
  margin-top: 0.3rem;
  font-weight: 500;
  font-size: 90%;
}

</style>
