import { postService } from '@/services/newpost'

function saveActivity (self, options = {}, callback) {
  const reqAddActivity = function () {
    return postService(self, options, 'saveActivity')
      .catch((err) => {
        callback(err)
      })
      .then((response) => {
        self.isWorking = false
        if (response && response.data && response.data.msg) {
          // alert(response.data.msg)
          return callback(new Error(response.data.msg))
        }
        callback(null, response.data)
      })
  }
  reqAddActivity()
}

export { saveActivity }
