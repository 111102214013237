import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Activity from '@/views/Activity.vue'
import Metrics from '@/views/Metrics.vue'
import Projects from '@/views/Projects.vue'
import Bookmark from '@/views/Bookmark.vue'
import Findings from '@/views/Findings.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Reset from '@/views/Reset.vue'
import { trackRouter } from "vue-gtag-next"

export default {
  install(app, options) {

    const cognito = app.config.globalProperties.$cognito

    function requireAuth (to, from, next) {
      cognito.isAuth((err, loggedIn) => {
        if (err) { return next() }
        if (!loggedIn) {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        } else {
          const user = cognito.getUser()
          if (to.params.username && to.params.username !== user.username) {
            router.push({ name: 'Err', params: { message: 'Privacy is of utmost importance.' } })
            return false
          } else {
            next()
          }
        }
      })
    }

    function logout (to, from, next) {
      // NOTE THAT WE DEACTIVATED THE EMIT LOGOUT
      // BECAUSE WE NEED TO UPDATE THE EVENT HUB FOR THE NEW VUE 3
      // router.app.$eventHub.$emit('logout')
      // router.app.$root.$emit('logout')
      const user = cognito.getUser()
      app.config.globalProperties.$gtag.event('logout', {
        event_category: 'user',
        event_label: (user ? user.username : 'n/a')
      })
      cognito.logout()
      next('/')
    }

    const routes = [
      { path: '/', name: 'Home', component: Home },
      { path: '/login', name: 'Login', component: Login },
      { path: '/login/:confirmUsername/:confirmCode', component: Login, props: true },
      { path: '/logout', name: 'Logout', beforeEnter: logout },
      { path: '/register', name: 'Register', component: Register },
      { path: '/reset', name: 'Reset', component: Reset },
      { path: '/reset/:resetUsername/:resetCode', component: Reset, props: true },
      { path: '/feed', name: 'Activity', component: Activity, beforeEnter: requireAuth },
      { path: '/metrics', name: 'Metrics', component: Metrics, beforeEnter: requireAuth },
      { path: '/projects', name: 'Projects', component: Projects, beforeEnter: requireAuth },
      { path: '/bookmark', name: 'Bookmark', component: Bookmark, beforeEnter: requireAuth },
      { path: '/findings', name: 'Findings', component: Findings, beforeEnter: requireAuth },
      { path: '/confirm/:confirmUsername/:confirmCode', redirect: '/login/:confirmUsername/:confirmCode' }
    ]

    function scrollBehavior (to) {
        if (to.hash) {
          return { selector: to.hash }
        } else {
          return { left: 0, top: 0 }
        }
    }

    const router = createRouter({
      history: createWebHistory(),
      base: '/',
      scrollBehavior: scrollBehavior,
      routes
    })

    trackRouter(router)

    router.install(app)
  }
}