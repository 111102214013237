import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js' // 'amazon-cognito-identity-js'

export default class Cognito {
  constructor() {
    this.userSession = null
    this.userId = null
    // this.options = opts
  }

  isAuth(cb) {
    const user = this.getUser()
    const that = this
    if (user) { // user != null
      user.getSession((err, session) => {
        if (err) {
          return cb(null, false)
        }
        that.userId = session.getIdToken().payload.sub
        // console.log(session.getIdToken().payload.email_verified)
        return cb(null, true)
      })
    } else {
      cb(null, false)
    }
  }

  configure(config) {
    // console.log('configuring', config)
    if (typeof config !== 'object' || Array.isArray(config)) {
      throw new Error('Valid option object required')
    }
    this.userPool = new CognitoUserPool({
      UserPoolId: process.env.VUE_APP_USER_POOL_ID,
      ClientId: process.env.VUE_APP_CLIENT_ID
    })
    AWS.config.region = process.env.VUE_APP_REGION
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID // process.env.VUE_APP_IDENTITY_POOL_ID
    })
    this.options = config
  }

  signup(username, email, pass, mailing, cb) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email
      })
      /*,
      new CognitoUserAttribute({
        Name: 'custom:mailing',
        Value: mailing ? '1' : '0'
      })*/
    ]
    this.userPool.signUp(username, pass, attributeList, null, (err) => {
      if (err) {
        // https://bugs.chromium.org/p/chromium/issues/detail?id=124534
        if (err.code && err.code === 'InvalidParameterException') {
          return cb(new Error('Please follow the instructions to choose a username and password'))
        }
        return cb(err)
      }
      cb(null)
    })
  }

  authenticate(username, pass, cb) {
    const that = this
    const authData = { Username: username, Password: pass }
    const authDetails = new AuthenticationDetails(authData)
    const userData = { Username: username, Pool: this.userPool }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: function (result) {
        // const verified = result.getIdToken().payload.email_verified
        // console.log('email_verified', result.getIdToken().payload.email_verified)
        that.userId = result.getIdToken().payload.sub
        // console.log(result.getAccessToken(), result.getIdToken().payload.sub)
        // that.userId = result.getIdToken().payload.sub
        // console.log('access token + ' + result.getAccessToken().getJwtToken())
        // const idToken = result.idToken.jwtToken
        // var logins = {}
        // logins['cognito-idp.' + that.options.region + '.amazonaws.com/' + that.options.UserPoolId] = result.getIdToken().getJwtToken()
        // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //   IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID, // that.options.UserPoolId,
        //   Logins: logins
        // })
        // console.log(AWS.config.credentials)
        that.onChange(true)
        cb(null, result)
      },
      onFailure: function (err) {
        cb(err)
      },
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        cb(new Error('You need to reset your password'))
      }
    })
  }

  getUserId() {
    // console.log('getting current userId', this.userId)
    // const user = this.userPool.getCurrentUser()
    return this.userId
  }

  getUser() {
    const user = this.userPool.getCurrentUser()
    if (!user) {
      return null
    }
    // console.log('getting current user', user)
    // user.userId = this.userId
    return user
  }

  confirmRegistration(username, code, cb) {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool
    })
    cognitoUser.confirmRegistration(code, true, cb)
  }

  /**
   * Logout of your cognito session.
   */
  logout() {
    const user = this.getUser()
    if (user) {
      user.signOut()
    }
    // this.getUser().signOut()
    this.userId = null
    this.onChange(false)
  }

  /**
   * Resolves the current token based on a user session. If there
   * is no session it returns null.
   * @param {*} cb callback
   */
  getIdToken(cb) {
    if (this.getUser() == null) {
      return cb(null, null)
    }
    this.getUser().getSession((err, session) => {
      if (err) {
        return cb(err)
      }
      if (session.isValid()) {
        return cb(null, session.getIdToken().getJwtToken())
      }
      cb(Error('Session is invalid'))
    })
  }

  onChange() {
    // console.log('changing')
  }

  resetPassword(username, cb) {
    // const self = this
    // const poolData = { UserPoolId: xxxx, ClientId: xxxx };
    // userPool is const userPool = new AWSCognito.CognitoUserPool(poolData);

    // setup cognitoUser first
    const userData = { Username: username, Pool: this.userPool }
    const cognitoUser = new CognitoUser(userData)

    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        // console.log('call result: ' + result)
        cb(null, result)
      },
      onFailure: function (err) {
        // alert(err)
        cb(err)
      }
      /* ,
      inputVerificationCode () { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
        var verificationCode = prompt('Please input verification code ', '')
        var newPassword = prompt('Enter new password ', '')
        self.confirmPassword(username, verificationCode, newPassword, cb)
      } */
    })
  }

  confirmPassword(username, verificationCode, newPassword, cb) {
    const userData = { Username: username, Pool: this.userPool }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: function (result) {
        // console.log('call result: ' + result)
        cb(null, result)
      },
      onFailure: function (err) {
        // alert(err)
        cb(err)
      }
    })
  }
}

// References used to update code to new Vue 3
// https://medium.com/swlh/how-to-create-a-vue-plugin-36d5987d4768
// https://v3.vuejs.org/guide/plugins.html#writing-a-plugin
// https://stackoverflow.com/questions/64040286/access-this-root-in-vue-js-3-setup
// https://v3.vuejs.org/guide/migration/events-api.html#overview
// https://v3.vuejs.org/guide/mixins.html#option-merging
// https://stackoverflow.com/questions/64782385/how-to-vue3-composition-api-plugin
// https://nystudio107.com/blog/a-taste-of-vue-js-3-async-components-and-plugins
// https://stackoverflow.com/questions/60755676/how-to-use-router-in-vue-composition-api
// https://stackoverflow.com/questions/66954284/access-vue-3-app-instance-api-from-non-vue-fiile
// import { getCurrentInstance } from "vue";
// https://stackoverflow.com/questions/64782385/how-to-vue3-composition-api-plugin

Cognito.install = function (app, options) {

  if (!app.config.globalProperties.$cognito) {
    const obj = new Cognito()
    obj.configure(options)
    app.config.globalProperties.$cognito = obj
  }

  app.mixin({
    beforeCreate() {
      // "this" in this context is the component being created (all of them),
      // including the root component "app"
      // this.$options are all the properties of the component
      if (this.$options.cognito) {
        this._cognito = this.$options.cognito
        this._cognito.configure(options)
      }
    }
  })
}
