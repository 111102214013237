<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <div id="app">
    <router-view />
  </div>
</template>
<style src="./assets/spectre.min.css"></style>
<style src="./assets/spectre-exp.min.css"></style>
<style src="./assets/spectre-icons.min.css"></style>
<style lang="css">
#app {
  /*margin: 6rem;*/
  /*  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50; */
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

body {
  font-family: Inter, sans-serif;
  font-size: 0.90rem !important;
  font-weight: 300 !important;
  color: #4C5059 !important;
  background-color: #EEEEEE;
}

h2, h3, h4, h5 {
  font-style: normal;
  font-weight: 800 !important;
  color: #333740 !important;
}

h2 {
  font-size: 1.8rem !important;
  line-height: 1.8rem !important;
  letter-spacing: -0.05rem;
}

h3 {
  margin-bottom: 1rem;
  font-size: 1.6rem !important;
  letter-spacing: -0.05rem;
}

h4 {
  /*margin-bottom: 1rem;*/
  font-size: 1.3rem !important;
  line-height: 1.3rem !important;
  letter-spacing: -0.05rem;
}

h5 {
  font-size: 1.1rem !important;
  line-height: 1.1rem !important;
  letter-spacing: -0.03rem;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 170% !important;
    line-height: 1.5rem !important;
  }

  h3 {
    font-size: 150% !important;
  }

  h4 {
    font-size: 130% !important;
    margin-bottom: 0.7rem;
  }

  h5 {
    font-size: 103% !important;
  }

  p {
    line-height: 1.3rem;
  }
}

a {
  text-decoration: underline !important;
  color: #5755D9 !important;
  cursor: pointer;
  font-weight: 600;
}

strong {
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
  margin-bottom: 1.2rem;
}

.btn-primary {
  border-radius: .3rem !important;
  height: 2.5rem !important;
}

.btn-secondary {
  border-radius: .3rem !important;
  height: 2.5rem !important;
}

.btn-sm {
  border-radius: .2rem !important;
}

.col-container {
  padding-right: 2rem;
}

.col-insight {
  font-size: 0.8rem;
}

.middot {
  font-size: 0.8rem;
  line-height: 0.6rem;
  font-weight: 900;
}

.title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #1F2126 !important;
  font-size: 2.8rem;
  line-height: 2.7rem;
  letter-spacing: -0.1rem;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: -0.07rem;
  }
}

.btn {
  height: 3rem;
  font-size: .9rem;
}

small {
  font-weight: 300 !important;
}

.shop-name {
  /*font-weight: 500 !important;*/
  /*border-bottom: 1px dashed #000;*/
  text-decoration: none;
}

/*.project {
  font-size: 0.9rem;
  padding-right: 3rem;
}

.project-title {
  font-weight: 900;
  margin-top: 2rem;
  margin-bottom: 1rem;
}*/

.section-spacer {
  height: 2rem;
}

.navbar-brand {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #533FEB !important;
  font-size: 1rem !important;
  letter-spacing: -0.04rem;
  text-decoration: none !important;
}

.navbar {
  padding: 0 0 0 5px;
  margin: 2rem 0 1rem 0;
  /*font-weight: 600;*/
}

.navbar-header {
  margin-top: 2px;
}

.footer {
  margin-bottom: 3rem;
}

.footer p {
  font-size:  0.75rem;
  line-height: 0.9rem;
  margin: 0 0 0.3rem;
}

.footer a {
  color: #5755D9;
}

@media only screen and (max-width: 600px) {
  .footer p {
    font-size: 0.85rem;
    line-height: 0.95rem;
  }
}

.maxPageWidth {
  /*max-width: 1024px;*/
}

</style>
