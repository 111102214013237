<template>
  <div>
    <SiteNav :username="username" />
    <!--  -->
    <table>
      <tr>
        <td>
          <div class="d-inline-block ml-2">
            <select class="form-select" id="findingsUserId" v-model="findingsUserId" @change="getFindings(null)">
              <option value="">All users</option>
              <option value="1">lucho</option>
              <option value="2">magui</option>
              <option value="4">barbichemes</option>
            </select>
          </div>
          <div class="d-inline-block ml-2">
            <select class="form-select" id="findingsTypeId" v-model="findingsTypeId" @change="getFindings(null)">
              <option value="">All finding types</option>
              <option value="50">Events</option>
              <option value="10">Sources</option>
              <option value="20">Companies</option>
              <option value="40">Content</option>
              <option value="30">Search</option>
            </select>
          </div>
          <div class="d-inline-block ml-2">
            <select class="form-select" id="findingsProjectId" v-model="findingsProjectId" @change="getFindings(null)">
              <option value="">All projects</option>
              <option v-for="project in projects" v-bind:value="project.ID" v-bind:key="project.ID">{{project.code}} / {{$filters.truncate(project.title, 40)}}</option>
            </select>
          </div>
          <div class="d-inline-block ml-2">
            <div class="has-icon-right">
              <input type="text" class="form-input" placeholder="Search topic" v-model="strSearchTopic" v-on:keyup="action">
              <i class="form-icon icon icon-search"></i>
            </div>
          </div>
          <div class="d-inline-block ml-2 mr-2"><a @click="getFindings(null)">Refresh</a></div>
          <div v-if="isWorking" class="d-inline-block loading ml-2"></div>
        </td>
      </tr>
    </table>
    <!--  -->
    <div class="container">
      <div class="columns">
        <div class="column col-lg-12 col-6 col-mx-auto">
          <table class="table-ifh">
            <tr v-bind:key="item.ID" v-for="(item) of findingItems" class="table-ifh-row">
              <td class="table-ifh-cell">
                <div>
                  <div class="item-title">{{item.title}}</div>
                  <div class="cell-content-title d-inline-block mr-2">
                    {{item.ID}} / {{item.code}}-{{item.job_id}}
                  </div>
                  <div class="d-inline-block ml-2">
                    {{friendlyDateFn(item.ts_added)}}
                    / {{typeIdToLabel(item.type_id)}}
                    / <a v-on:click="toggleFindingModal(item)">Edit</a>
                    / <span class="person-label"><i class="icon icon-people icon-small"></i> {{item.username}}</span>
                  </div>
                  <div class="d-inline-block ml-2" v-html="topicsToChips(item.topics)">
                  </div>
                </div>
                <div class="divider"></div>
                <div>
                  <!-- <div v-if="!item.querystring" class="text-bold">{{item.title}}</div> -->
                  <div v-if="item.querystring" class="text-bold text-success">{{item.querystring}}</div>
                  <div><a v-on:click="openResource(item.url, $event)">{{$filters.truncate(item.url, 60)}}</a></div>
                </div>
                <div v-if="item.notes">
                  <div class="divider"></div>
                  <div class="cell-content-block">{{item.notes}}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="column col-lg-12 col-6 col-mx-auto">
          <table class="table-ifh">
            <tbody>
              <tr class="table-ifh-row">
                <td class="table-ifh-cell">
                  <div class="text-bold">Search queries</div>
                  <div v-bind:key="idx" v-for="(item, idx) of uniqueQueryStrings">{{item}}</div>
                  <br>
                  <div class="text-bold">Companies</div>
                  <div v-bind:key="item.name" v-for="(item) of uniqueCompanies"><a :href="item.url" target="_blank">{{item.name}}</a></div>
                  <br>
                  <div class="text-bold">Domains</div>
                  <div v-bind:key="item.domain" v-for="(item) in topDomains"><a :href="'https://' + item.domain" target="_blank">{{item.domain}}</a> ({{item.count}})</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal modal-lg" :class="{active: showFindingModal}">
      <a v-on:click="toggleFindingModal()" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container">
        <div class="modal-header">
          <a v-on:click="toggleFindingModal()" class="btn btn-clear float-right" aria-label="Close"></a>
        </div>
        <div class="modal-body">
          <h3 class="main-title">
            Edit finding
          </h3>
          <!-- FORM -->
          <form @submit.prevent="saveFinding">
            <div class="form-group">
              <input id="findingTitle" ref="findingTitle" type="text" class="form-input" v-model.trim="findingTitle" placeholder="Title" autocomplete="off">
            </div>
            <div class="form-group">
              <textarea id="findingNotes" ref="findingNotes" class="form-input" v-model.trim="findingNotes" placeholder="Notes" rows="2"></textarea>
            </div>
            <div class="form-group">
              <TopicSelect :fieldId="topicFieldId" :initialTopics="findingTopics" :topicOptions="topicOptions" v-on:topicSelectEmit="topicUpdate" />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="float-left">
            <a v-on:click="delFinding(findingId)" class="m-2">delete</a>
          </div>
          <div class="float-right">
            <!-- btn cancel -->
            <button class="btn btn-sm mr-2" v-on:click="toggleFindingModal()">Cancel</button>
            <!-- btn save -->
            <button class="btn btn-sm" :class="{loading: isWorking}" v-on:click="saveFinding">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav.vue'
import { postService } from '@/services/newpost'
import { sortMultiple } from '@/services/sort'
import { getFindings } from '@/services/findings/get'
import handleError from '@/services/handle-error'
import TopicSelect from '@/components/TopicSelect.vue'
import date from 'date-and-time'
export default {
  name: 'Findings',
  title: function () {
    return 'Intel for hire :: Findings'
  },
  components: {
    SiteNav,
    TopicSelect
  },
  data: function () {
    return {
      isWorking: false,
      projects: [],
      projectsIdx: [],
      findings: [],
      findingsIdx: [],
      findingsUserId: '',
      findingsTypeId: '',
      findingsProjectId: '',
      latestBookmarkId: null,
      showFindingModal: false,
      findingId: null,
      findingTitle: '',
      findingNotes: '',
      findingTopics: [],
      topicFieldId: 'topics',
      strSearchTopic: ''
    }
  },
  created: function () {
    const self = this
    this.user = this.$cognito.getUser()
    this.userCognitoId = this.$cognito.getUserId()
    this.username = this.user ? this.user.username : ''
  },
  mounted: function () {
    const self = this
    self.getFindings(null)
    self.getProject()
    window.addEventListener('storage', function () {
      const latestId = parseInt(localStorage.getItem('mostRecentBookmarkId'))
      if (self.latestBookmarkId < latestId) {
        self.getFindings(latestId)
      }
      // do your checks to detect
      // changes in "e1", "e2" & "e3" here
    }, false)
  },
  computed: {
    topDomains: function () {
      const self = this
      const unique = {}
      this.findingItems.slice().filter(f => f.domain).map(f => {
        unique[f.domain] = unique[f.domain] ? unique[f.domain] + 1 : 1
      })
      return Object.keys(unique).map(domain => {
        return {
          domain: domain,
          count: unique[domain]
        }
      }).sort(sortMultiple('-count', 'domain'))
    },
    uniqueCompanies: function () {
      const self = this
      const unique = {}
      return this.findingItems.slice()
        .filter(f => parseInt(f.type_id) === 20) // companies
        .map(f => {
          if (unique[f.title]) {
            return { name: 'dup' }
          }
          unique[f.title] = 1
          return {
            name: f.title,
            url: f.url
          }
        })
        .filter(f => f.name !== 'dup')
        .sort(sortMultiple('name'))
    },
    uniqueQueryStrings: function () {
      const self = this
      return this.findingItems.slice()
        .map(f => f.querystring)
    },
    findingItems: function () {
      const self = this
      return this.findings.slice()
        .filter(f => self.strSearchTopic ? (f.topics && f.topics.toLowerCase().indexOf(self.strSearchTopic.toLowerCase()) !== -1) : true)
        .sort(sortMultiple('-ID'))
    },
    topicOptions: function () {
      let topic = {}
      this.findings.slice().filter(f => f.topics).map(f => {
        f.topics.split(',').map(x => {
          topic[x] = 1
        })
      })
      return Object.keys(topic)
    }
  },
  methods: {
    action: function (data) {
      if (!data || ['Escape', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].indexOf(data.key) === -1) {
        return false
      }
      if (data.key === 'Escape') {
        this.strSearchTopic = ''
      }
    },
    getFindings: function (id) {
      const self = this
      this.isWorking = true
      const options = {
        limit: 1000,
        jobId: this.$route.query.job_id,
        projectId: this.$route.query.project_id
      }
      if (id) {
        options['ID'] = id
      }
      if (this.findingsUserId) {
        options['userId'] = parseInt(this.findingsUserId)
      }
      if (this.findingsTypeId) {
        options['typeId'] = parseInt(this.findingsTypeId)
      }
      if (this.findingsProjectId) {
        options['projectId'] = parseInt(this.findingsProjectId)
      }
      getFindings(this, options, (err) => {
        handleError(err)
        self.isWorking = false
      })
    },
    delFinding: function (id) {
      const self = this
      if (!confirm('Are you sure you want to delete?')) {
        return false
      }
      self.toggleFindingModal()
      const reqDelFinding = function () {
        return postService(self, { id: id }, 'deleteBookmark')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            let idx = self.findingsIdx.indexOf(id)
            if (idx !== -1) {
              self.findings.splice(idx, 1)
              self.findingsIdx.splice(idx, 1)
            }
            self.emptyFinding()
          })
      }
      reqDelFinding()
    },
    emptyFinding: function () {
      this.findingId = null
      this.findingTitle = ''
      this.findingNotes = ''
      this.findingTopics = []
    },
    saveFinding: function () {
      const self = this
      self.isWorking = true
      const payload = {
        ID: this.findingId,
        title: this.findingTitle,
        notes: this.findingNotes,
        topics: this.findingTopics.join(',')
      }
      const reqSaveFinding = function () {
        return postService(self, payload, 'saveBookmark')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            self.isWorking = false
            if (response && response.data && response.data.msg) {
              alert(response.data.msg)
              return false
            }
            let idx = self.findingsIdx.indexOf(self.findingId)
            if (idx !== -1) {
              self.findings[idx].title = self.findingTitle
              self.findings[idx].notes = self.findingNotes
              self.findings[idx].topics = self.findingTopics.join(',')
            }
            self.toggleFindingModal()
            self.emptyFinding()
          })
      }
      reqSaveFinding()
    },
    topicUpdate: function (data) {
      this.findingTopics = data
    },
    toggleFindingModal: function (finding) {
      this.showFindingModal = !this.showFindingModal
      if (this.showFindingModal) {
        this.findingId = finding.ID
        this.findingTitle = finding.title
        this.findingNotes = finding.notes
        this.findingTopics = finding.topics ? finding.topics.split(',') : []
        this.$nextTick(() => {
          this.$refs.findingTitle.focus()
        })
      } else {
        this.emptyFinding()
      }
    },
    topicsToChips: function (str) {
      return str ? str.split(',').map(t => { return `<span class="chip">${t}</span>` }).join('') : ''
    },
    typeIdToLabel: function (typeId) {
      switch (typeId) {
      case 10:
        return 'Source'
        break
      case 20:
        return 'Company'
        break
      case 30:
        return 'Search'
        break
      case 40:
        return 'Content'
        break
      case 50:
        return 'Event'
        break
      default:
        return ''
      }
    },
    friendlyDateFn: function (str) {
      return str ? date.format(new Date(str), 'ddd MMM DD') : ''
    },
    openResource: function (url, e) {
      e.stopPropagation()
      window.open(url, '_blank').focus()
    },
    getProject: function () {
      const self = this
      const reqGetProject = function () {
        return postService(self, {}, 'project')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            response.data.map(x => {
              let idx = self.projectsIdx.indexOf(x.ID)
              if (idx === -1) {
                self.projects.push(x)
                self.projectsIdx.push(x.ID)
              } else {
                self.projects[idx] = x
              }
            })
          })
      }
      reqGetProject()
    }
  }
}

</script>
<style lang="css" scoped>
a {
  font-weight: normal;
}

.modal-container .modal-body {
  overflow-y: unset;
}

.nowrap {
  white-space: nowrap;
}

</style>
