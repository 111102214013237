<template>
  <div>
    <SiteNav :username="username" />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-12 col-8 col-mx-auto">
          <table style="margin: 0; padding: 0;">
            <tr>
              <td>
                <div class="col-title">Jobs</div>
              </td>
              <td>
                <button v-if="userLevel <= 20" class="btn btn-sm ml-2" v-on:click="toggleJobModal()">Add job</button>
              </td>
              <td>
                <label class="form-checkbox ml-2">
                  <input type="checkbox" v-model="showJobs.everyone" v-on:click="toggleJobsView('everyone')">
                  <i class="form-icon"></i> Everyone's
                </label>
              </td>
              <td>
                <label class="form-checkbox">
                  <input type="checkbox" v-model="showJobs.pending" v-on:click="toggleJobsView('pending')">
                  <i class="form-icon"></i> Pending
                </label>
              </td>
              <td>
                <label class="form-checkbox">
                  <input type="checkbox" v-model="showJobs.inprogress" v-on:click="toggleJobsView('inprogress')">
                  <i class="form-icon"></i> In progress
                </label>
              </td>
              <td>
                <label class="form-checkbox">
                  <input type="checkbox" v-model="showJobs.complete" v-on:click="toggleJobsView('complete')">
                  <i class="form-icon"></i> Complete
                </label>
              </td>
              <td>
                <label class="form-checkbox">
                  <input type="checkbox" v-model="showJobs.canceled" v-on:click="toggleJobsView('canceled')">
                  <i class="form-icon"></i> Canceled
                </label>
              </td>
              <td>
                <div v-if="isLoadingJobs" class="d-inline-block loading ml-2"></div>
              </td>
            </tr>
          </table>
          <table class="table-ifh" style="margin-top: -9px;">
            <tr v-bind:key="item.ID" v-for="(item) of jobItems" class="table-ifh-row">
              <td class="table-ifh-cell">
                <div>
                  <div class="float-right mt-2">
                    <button class="btn btn-sm" v-on:click="toggleActivityModal(item.ID, item.project_id)"><i class="icon icon-plus"></i></button>
                  </div>
                  <span class="item-title">
                    {{item.title}}
                  </span><br>
                  <span class="cell-content-title">
                    {{item.code}}-{{item.ID}}
                  </span>
                  <span class="label label-primary label-status ml-1" :class="jobLabelColor[item.status_id]">{{jobStatus[item.status_id]}}</span>
                  <a v-on:click="editJob(item.ID)" class="text-normal ml-2">Edit</a>
                  <span v-if="username==='lucho'">
                    / <a v-on:click="delJob(item.ID)" class="text-normal">Delete</a>
                  </span>
                </div>
                <div class="divider"></div>
                <div v-if="item.description" class="cell-content-block">For <span class="person-label"><i class="icon icon-people icon-small"></i> {{item.assignee}}</span>: {{item.description}}</div>
                <div class="cell-content-block">
                </div>
                <span v-if="item.resource_a" class="resource-label mr-2 text-normal">
                  <i class="icon icon-link icon-small"></i> <a v-on:click="openResource(item.resource_a, $event)">Resource A</a>
                </span>
                <span v-if="item.resource_b" class="resource-label mr-2 text-normal">
                  <i class="icon icon-link icon-small"></i> <a v-on:click="openResource(item.resource_b, $event)">Resource B</a>
                </span>
                <div class="divider"></div>
                <!-- <div class="text-bold">Activities</div> -->
                <div style="margin-top: 10px">
                  <div v-bind:key="activity.ID" v-for="(activity) of activitiesByJob(item.ID)" style="margin-bottom: 10px;">
                    <div v-if="!activity.ts_end" class="d-inline-block loading mr-2">div</div>
                    <span class="person-label"><i class="icon icon-people icon-small"></i> {{activity.username}}</span> – {{activity.type}}
                    <span v-if="activity.username===username">
                      /
                      <span v-if="!activity.ts_end"><a v-on:click="stopActivity(activity.ID)" class="text-normal">Stop</a></span>
                      <span v-else><a v-on:click="resume(activity.ID)" class="text-normal">Resume</a></span>
                      / <a v-on:click="editActivity(activity.ID)" class="text-normal">Edit</a>
                      / <a v-on:click="delActivity(activity.ID)" class="text-normal">Delete</a>
                    </span>
                    <div v-show="activity.description" class="cell-content-block text-italic">
                      {{activity.description}}
                    </div>
                    <div>
                      <span v-if="activity.ts_end">
                        Ended on {{friendlyDateFn(activity.ts_start)}}
                        at <span v-html="timeFn(activity.ts_end)"></span> ({{activity.minutes}} mins)
                      </span>
                      <span v-else>
                        Started at <span v-html="timeFn(activity.ts_start)"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div v-show="!jobItems.length" class="loading loading-lg"></div>
          <div v-show="!lastJobEntry && jobItems.length" style="width: 100%; text-align: center;">
            <button class="btn btn-sm mt-2 mr-2" :class="{loading: isLoadingJobs}" v-on:click="jobOffset = jobOffset + 10; getJobs()">Show more</button>
            <div class="section-spacer"></div>
          </div>
        </div>
        <div class="column col-lg-12 col-4 col-mx-auto">
          <table style="margin: 0; padding: 0;">
            <tr>
              <td>
                <div class="col-title">
                  Projects
                </div>
              </td>
              <td>
                <button v-if="userLevel <= 20" class="btn btn-sm ml-2" v-on:click="toggleProjectModal()">Add project</button>
              </td>
            </tr>
          </table>
          <table class="table-ifh">
            <tr v-bind:key="item.ID" v-for="(item) of projectItems" class="table-ifh-row" v-on:click="editProject(item.ID)">
              <td class=" table-ifh-cell">
                <div>
                  <span class="cell-content-title">
                    {{item.code}} /
                    {{item.title}}
                  </span>
                </div>
                <div v-if="item.description" class="cell-content-block">{{item.description}}</div>
                <div class="cell-content-block">
                </div>
              </td>
            </tr>
          </table>
          <div v-show="!projectItems.length" class="loading loading-lg"></div>
        </div>
      </div>
    </div>
    <!-- ================ BEGINS ACTIVITY MODAL ================ -->
    <div class="modal modal-lg" :class="{active: showActivityModal}">
      <a v-on:click="toggleActivityModal()" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container">
        <div class="modal-header">
          <a v-on:click="toggleActivityModal()" class="btn btn-clear float-right" aria-label="Close"></a>
        </div>
        <div class="modal-body">
          <h3 class="main-title">
            <span v-if="isEditable(activityCognitoId, activityActivityId)">
              <span v-html="activityActivityId? 'Edit' : 'New'"></span> activity
            </span>
            <span v-else>
              Activity
            </span>
          </h3>
          <form @submit.prevent="addActivity">
            <!-- <input id="activityJobId" type="hidden" :value="activityJobId" /> -->
            <div class="form-group mb-1 mr-1">
              <!--               <div class="form-inline mb-1 mr-1">
                <span v-if="activityActivityId && activeJobsIdx.indexOf(activityJobId) === -1">
                  {{activityLabelId(activityProjectCode, activityJobId)}}
                  <span v-if="activityJobTitle"> / </span>{{activityJobTitle}}
                </span>
                <select v-else class="form-select" id="activityJobId" v-model="activityJobId" autocomplete="off" :disabled="!isEditable(activityCognitoId, activityActivityId)">
                  <option value="">Choose a job</option>
                  <option v-for="job in activeJobs" v-bind:value="job.ID">
                    {{job.code}}-{{job.ID}} / {{job.title}}
                  </option>
                </select>
              </div> -->
              <div class="form-inline mb-1 mr-1">
                <select class="form-select" id="activityType" v-model="activityType" :disabled="!isEditable(activityCognitoId, activityActivityId)">
                  <option value="">Choose a type of activity</option>
                  <option v-for="type in sortedTypes" v-bind:value="type.ID" v-bind:key="type.ID">
                    {{type.title}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-inline mb-1 mr-1">
              <input id="startDate" type="text" class="form-input" v-model="startDate" placeholder="Start date (MM/DD/YYYY)" autocomplete="off" :disabled="!isEditable(activityCognitoId, activityActivityId)">
            </div>
            <div class="form-inline mb-1 mr-1">
              <input id="startTime" type="text" class="form-input" v-model="startTime" placeholder="Start time" autocomplete="off" :disabled="!isEditable(activityCognitoId, activityActivityId)">
            </div>
            <div class="form-inline mb-1 mr-1">
              <input id="endTime" type="text" class="form-input" v-model="endTime" placeholder="End time" autocomplete="off" :disabled="!isEditable(activityCognitoId, activityActivityId)">
            </div>
            <div class="form-inline mb-1 mr-1">
              <button class="btn btn-sm" @click.prevent="startNow" v-show="isEditable(activityCognitoId, activityActivityId)">Start now</button>
            </div>
            <div class="form-inline mb-1 mr-1">
              <button class="btn btn-sm" @click.prevent="endNow" v-show="isEditable(activityCognitoId, activityActivityId)">End now</button>
            </div>
            <div class="form-group">
              <input id="description" ref="description" type="text" class="form-input" v-model.trim="description" placeholder="Description" autocomplete="off" :disabled="!isEditable(activityCognitoId, activityActivityId)">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <!--           <div class="float-left">
            <a v-show="isEditable(activityCognitoId, activityActivityId) && activityActivityId" v-on:click="delActivity(activityActivityId)" class="m-2">delete</a>
          </div> -->
          <div class="float-right">
            <button class="btn btn-sm mr-2" v-on:click="toggleActivityModal()">Cancel</button>
            <button class="btn btn-sm" :class="{loading: isWorking}" v-on:click="addActivity()" v-show="isEditable(activityCognitoId, activityActivityId)">Save</button>
          </div>
        </div>
        <div class="section-spacer"></div>
        <div class="section-spacer"></div>
      </div>
    </div>
    <!-- ================ ENDS ACTIVITY MODAL ================ -->
    <!-- ================ BEGINS JOB MODAL ================ -->
    <div class="modal modal-lg" :class="{active: showJobModal}">
      <a v-on:click="toggleJobModal()" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container">
        <div class="modal-header">
          <a v-on:click="toggleJobModal()" class="btn btn-clear float-right" aria-label="Close"></a>
        </div>
        <div class="modal-body">
          <h3 class="main-title">
            <span v-if="isEditable(jobCreatorCognitoId, jobJobId)">
              <span v-html="jobJobId? 'Edit' : 'New'"></span> job
            </span>
            <span v-else>
              Job
            </span>
          </h3>
          <!-- FORM -->
          <form @submit.prevent="addJob">
            <div class="form-group">
              <!-- select project Id -->
              <div class="form-inline mb-1 mr-1">
                <select class="form-select" id="jobProjectId" v-model="jobProjectId" :disabled="!isEditable(jobCreatorCognitoId, jobJobId)">
                  <option value="">Choose a project</option>
                  <option v-for="project in projects" v-bind:value="project.ID" v-bind:key="project.ID">
                    {{project.code}}
                  </option>
                </select>
              </div>
              <div class="form-inline mr-1">
                <!-- input job assignee -->
                <!-- <input id="jobAssignee" ref="jobAssignee" type="text" class="form-input" v-model.trim="jobAssignee" placeholder="Assignee" autocomplete="off" :disabled="!isEditable(jobCreatorCognitoId, jobJobId)"> -->
                <select class="form-select" id="jobAssignee" v-model="jobAssignee" :disabled="!isEditable(jobCreatorCognitoId, jobJobId)">
                  <option value="">Choose an assignee</option>
                  <option value="lucho">lucho</option>
                  <option value="magui">magui</option>
                  <option value="barbichemes">barbichemes</option>
                  <option value="researcher">researcher</option>
                </select>
              </div>
              <div class="form-inline mb-1 mr-1">
                <!-- select job status id -->
                <select class="form-select" id="jobStatusId" v-model="jobStatusId" :disabled="!(isEditable(jobAssigneeCognitoId, jobJobId) || isEditable(jobCreatorCognitoId, jobJobId))">
                  <option value="">Choose a status</option>
                  <option v-for="(value, name) in jobStatus" v-bind:value="name" v-bind:key="name">
                    {{value}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <input id="jobTitle" ref="jobTitle" type="text" class="form-input" v-model.trim="jobTitle" placeholder="Title" autocomplete="off" :disabled="!isEditable(jobCreatorCognitoId, jobJobId)">
            </div>
            <div class="form-group">
              <textarea v-if="isEditable(jobCreatorCognitoId, jobJobId)" id="jobDescription" ref="jobDescription" class="form-input" v-model.trim="jobDescription" placeholder="Description" rows="5"></textarea>
              <div v-else class="content-area">{{jobDescription}}</div>
            </div>
            <div class="form-group">
              <input id="jobResourceA" ref="jobResourceA" type="text" class="form-input" v-model.trim="jobResourceA" placeholder="Resource A" autocomplete="off" :disabled="!(isEditable(jobAssigneeCognitoId, jobJobId) || isEditable(jobCreatorCognitoId, jobJobId))">
            </div>
            <div class="form-group">
              <input id="jobResourceB" ref="jobResourceB" type="text" class="form-input" v-model.trim="jobResourceB" placeholder="Resource B" autocomplete="off" :disabled="!(isEditable(jobAssigneeCognitoId, jobJobId) || isEditable(jobCreatorCognitoId, jobJobId))">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="float-left">
            <a v-show="isEditable(jobCreatorCognitoId, jobJobId) && jobJobId" v-on:click="delJob(jobJobId)" class="m-2">delete</a>
          </div>
          <div class="float-right">
            <!-- btn cancel -->
            <button class="btn btn-sm mr-2" v-on:click="toggleJobModal()">Cancel</button>
            <!-- btn save -->
            <button class="btn btn-sm" :class="{loading: isWorking}" v-on:click="addJob" v-show="isEditable(jobAssigneeCognitoId, jobJobId) || isEditable(jobCreatorCognitoId, jobJobId)">Save</button>
          </div>
        </div>
        <div class="section-spacer"></div>
        <div class="section-spacer"></div>
      </div>
    </div>
    <!-- ================ ENDS JOB MODAL ================ -->
    <!-- ================ BEGINS PROJECT MODAL ================ -->
    <div class="modal modal-lg" :class="{active: showProjectModal}">
      <a v-on:click="toggleProjectModal()" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container">
        <div class="modal-header">
          <a v-on:click="toggleProjectModal()" class="btn btn-clear float-right" aria-label="Close"></a>
        </div>
        <div class="modal-body">
          <h3 class="main-title">
            <span v-if="isEditable(projectCognitoId, projectProjectId)">
              <span v-html="projectProjectId? 'Edit' : 'New'"></span> project
            </span>
            <span v-else>
              Project
            </span>
          </h3>
          <form @submit.prevent="addProject">
            <div class="form-group">
              <div class="form-inline mb-1 mr-1">
                <input id="projectCode" ref="projectCode" type="text" class="form-input" v-model.trim="projectCode" placeholder="Code" autocomplete="off" :disabled="!isEditable(projectCognitoId, projectProjectId)">
              </div>
              <div class="form-inline mb-1 mr-1" style="width: 75%;">
                <input id="projectTitle" ref="projectTitle" type="text" class="form-input" v-model.trim="projectTitle" placeholder="Title" autocomplete="off" :disabled="!isEditable(projectCognitoId, projectProjectId)">
              </div>
            </div>
            <div class="form-group">
              <textarea v-if="isEditable(projectCognitoId, projectProjectId)" id="projectDescription" ref="projectDescription" class="form-input" v-model.trim="projectDescription" placeholder="Description" rows="3">
                    </textarea>
              <div v-else class="content-area">{{projectDescription}}</div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="float-left">
            <a v-show="isEditable(projectCognitoId, projectProjectId) && projectProjectId" v-on:click="delProject(projectProjectId)" class="m-2">delete</a>
          </div>
          <div class="float-right">
            <button class="btn btn-sm mr-2" v-on:click="toggleProjectModal()">Cancel</button>
            <button class="btn btn-sm" :class="{loading: isWorking}" v-on:click="addProject" v-show="isEditable(projectCognitoId, projectProjectId)">Save</button>
          </div>
        </div>
        <div class="section-spacer"></div>
        <div class="section-spacer"></div>
      </div>
    </div>
    <!-- ================ ENDS PROJECT MODAL ================ -->
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav.vue'
import parseTime from '@/services/parse-time'
import { postService } from '@/services/newpost'
import { sortMultiple } from '@/services/sort'
import handleError from '@/services/handle-error'
import { getJobs } from '@/services/jobs/get'
import { saveActivity } from '@/services/activities/save'
import date from 'date-and-time'
import moment from 'moment'

export default {
  name: 'Activity',
  title: function () {
    return 'Intel for hire :: Activity'
  },
  components: {
    SiteNav
  },
  data: function () {
    return {
      types: [],
      jobStatus: {
        '10': 'pending',
        '20': 'in progress',
        '30': 'complete',
        '40': 'canceled'
      },
      jobLabelColor: {
        '10': 'bg-warning',
        '20': 'bg-primary',
        '30': 'bg-success',
        '40': 'bg-error'
      },
      //
      activity: [],
      activityIdx: [],
      activityActivityId: null,
      activityJobId: '',
      activityJobTitle: '',
      activityProjectId: '', // empty string so that the select form field displays "choose ..."
      activityProjectCode: '',
      activityCognitoId: null,
      activityType: '',
      activityOffset: 0,
      startDate: this.getToday(),
      startTime: '',
      endTime: '',
      description: '',
      showActivityModal: false,
      lastActivityEntry: false,
      isLoadingActivities: false,
      //
      projects: [],
      projectsIdx: [],
      projectProjectId: null,
      projectCognitoId: null,
      projectCode: '',
      projectTitle: '',
      projectDescription: '',
      showProjectModal: false,
      //
      jobs: [],
      activeJobs: [],
      jobsIdx: [],
      activeJobsIdx: [],
      showJobModal: false,
      jobJobId: null,
      jobProjectId: '',
      jobAssignee: '',
      jobCreatorCognitoId: null,
      jobAssigneeCognitoId: null,
      jobTitle: '',
      jobDescription: '',
      jobStatusId: '',
      jobResourceA: '',
      jobResourceB: '',
      jobOffset: 0,
      lastJobEntry: false,
      isLoadingJobs: false,
      showJobs: {
        pending: localStorage.jobsViewpending === 'true',
        inprogress: localStorage.jobsViewinprogress === 'true',
        everyone: localStorage.jobsVieweveryone === 'true',
        complete: localStorage.jobsViewcomplete === 'true',
        canceled: localStorage.jobsViewcanceled === 'true'
      },
      //
      isWorking: false,
      username: '',
      userLevel: 99
    }
  },
  created: function () {
    const self = this
    this.user = this.$cognito.getUser()
    this.userCognitoId = this.$cognito.getUserId()
    // this.userCognitoId = '9570e5cd-a00f-4edd-b250-7568559ecca0'
    this.username = this.user ? this.user.username.toLowerCase() : ''
    this.$cognito.isAuth((err, isLogged) => {
      // self.loggedIn = isLogged
      var user = self.$cognito.getUser()
      // console.log(user, self.$cognito.getUserId())
      self.username = user ? user.username.toLowerCase() : ''
      // self.userId = self.$cognito.getUserId()
      // self.roleId = self.$cognito.getRoleId()
      if (err) {
        console.log('Home', `Couldn't get the session ${err} ${err.stack}`)
      }
    })
  },
  mounted: function () {
    this.getMeta()
    this.getProject()
    this.getJobs()
    // this.getActivity()
  },
  computed: {
    projectItems: function () {
      return this.projects.slice()
        .sort(sortMultiple('-ID'))
    },
    jobItems: function () {
      return this.jobs.slice()
        .sort(sortMultiple('-ID'))
    },
    activityItems: function () {
      return this.activity.slice()
        .sort(sortMultiple('-in_progress', '-ts_start'))
    },
    sortedTypes: function () {
      return this.types.slice()
        .sort(sortMultiple('title'))
    }
  },
  methods: {
    activitiesByJob: function (jobId) {
      return this.activity.slice()
        .filter(a => a.job_id === jobId)
        .sort(sortMultiple('-in_progress', '-ts_start'))
    },
    getJobs: function (id = null) {
      const self = this
      self.isLoadingJobs = true
      let options = {}
      if (id) {
        options['ID'] = id
      }
      if (!self.showJobs['everyone']) {
        options['username'] = self.username
      }
      let statuses = [] // 10 = pending, 20 = in progress, 30 = complete, 40 = canceled
      if (this.showJobs['pending']) {
        statuses.push(10)
      }
      if (this.showJobs['inprogress']) {
        statuses.push(20)
      }
      if (this.showJobs['complete']) {
        statuses.push(30)
      }
      if (this.showJobs['canceled']) {
        statuses.push(40)
      }
      if (statuses.length) {
        options['statusIds'] = statuses.join(',')
      }
      getJobs(this, options, (err) => {
        handleError(err)
        self.isLoadingJobs = false
      })
    },
    toggleJobsView: function (item) {
      this.showJobs[item] = !this.showJobs[item]
      this.getJobs()
      localStorage.setItem(`jobsView${item}`, this.showJobs[item])
    },
    openResource: function (url, e) {
      e.stopPropagation()
      window.open(url, '_blank').focus()
    },
    isEditable: function (cognitoId, itemId = null) {
      // console.log('isEditable', cognitoId, itemId)
      return itemId === null || cognitoId === this.userCognitoId
    },
    activityLabelId: function (projectCode, jobId) {
      return `${projectCode}${jobId ? '-' + jobId : ''}`
    },
    emptyProjectItem: function () {
      this.projectProjectId = null
      this.projectCognitoId = null
      this.projectDescription = ''
      this.projectTitle = ''
      this.projectCode = ''
    },
    emptyJobItem: function () {
      this.jobJobId = null
      this.jobProjectId = ''
      this.jobCreatorCognitoId = null
      this.jobAssigneeCognitoId = null
      this.jobAssignee = ''
      this.jobDescription = ''
      this.jobTitle = ''
      this.jobResourceA = ''
      this.jobResourceB = ''
      this.jobStatusId = ''
    },
    emptyActivityItem: function () {
      this.activityActivityId = null
      this.activityCognitoId = null
      this.activityProjectId = ''
      this.activityProjectCode = ''
      this.activityJobId = ''
      this.activityJobTitle = ''
      this.description = ''
      this.activityType = ''
      this.startDate = ''
      this.startTime = ''
      this.endTime = ''
    },
    projectProps: function (activityProjectId) {
      let idx = this.projectsIdx.indexOf(activityProjectId)
      return idx === -1 ? {} : this.projects[idx]
    },
    // ===========
    toggleProjectModal: function () {
      this.showProjectModal = !this.showProjectModal
      if (this.showProjectModal) {
        this.$nextTick(() => {
          this.$refs.projectCode.focus()
        })
      } else {
        this.emptyProjectItem()
      }
    },
    toggleJobModal: function () {
      this.showJobModal = !this.showJobModal
      if (this.showJobModal) {
        this.$nextTick(() => {
          this.$refs.jobTitle.focus()
        })
      } else {
        this.emptyJobItem()
      }
    },
    addJob: function (evt) {
      evt.preventDefault()
      const self = this
      if (!self.jobProjectId) {
        alert('Missing job project Id!')
        return false
      }
      if (!self.jobTitle) {
        alert('Missing job title!')
        return false
      }
      self.isWorking = true
      const payload = {
        project_id: self.jobProjectId,
        title: self.jobTitle,
        description: self.jobDescription,
        resource_a: self.jobResourceA,
        resource_b: self.jobResourceB,
        assignee: self.jobAssignee,
        status_id: parseInt(self.jobStatusId)
      }
      if (self.jobJobId) {
        payload['ID'] = self.jobJobId
      }
      const reqAddJob = function () {
        return postService(self, payload, 'saveJob')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            self.isWorking = false
            if (response && response.data && response.data.msg) {
              alert(response.data.msg)
              return false
            }
            self.getJobs(self.jobJobId || response.data)
            self.emptyJobItem()
            self.toggleJobModal()
            // console.log('done', response)
          })
      }
      reqAddJob()
    },
    addProject: function (evt) {
      evt.preventDefault()
      const self = this
      if (!self.projectCode) {
        alert('Missing project code!')
        return false
      }
      if (!self.projectTitle) {
        alert('Missing project title!')
        return false
      }
      self.isWorking = true
      const payload = {
        code: self.projectCode,
        title: self.projectTitle,
        description: self.projectDescription
      }
      if (self.projectProjectId) {
        payload['ID'] = self.projectProjectId
      }
      const reqAddProject = function () {
        return postService(self, payload, 'saveProject')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            self.isWorking = false
            if (response && response.data && response.data.msg) {
              alert(response.data.msg)
              return false
            }
            self.getProject(self.projectProjectId || response.data)
            self.emptyProjectItem()
            self.toggleProjectModal()
            // console.log('done', response)
          })
      }
      reqAddProject()
    },
    // ===========
    toggleActivityModal: function (jobId, projectId) {
      const self = this
      this.showActivityModal = !this.showActivityModal
      if (this.showActivityModal) {
        this.activityJobId = jobId ? jobId : this.activityJobId
        this.activityProjectId = projectId ? projectId : this.activityProjectId
        this.$nextTick(() => {
          self.$refs.description.focus()
        })
      } else {
        this.emptyActivityItem()
      }
    },
    friendlyDateFn: function (str) {
      return str ? date.format(new Date(str), 'ddd MMM DD') : ''
    },
    dateFn: function (str) {
      return str ? date.format(new Date(str), 'MM/DD/YYYY') : ''
    },
    timeFn: function (str) {
      function formatAMPM (date) { // https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours : 12 // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
      }
      return str ? formatAMPM(new Date(str)) : '' // date.format(new Date(str), 'hh:mm A') : ''
    },
    getToday: function () {
      // https://stackoverflow.com/questions/1531093/how-do-i-get-the-current-date-in-javascript
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      var yyyy = today.getFullYear()
      return mm + '/' + dd + '/' + yyyy
    },
    startNow: function () {
      this.startDate = this.getToday()
      this.startTime = date.format(new Date(), 'hh:mm A')
    },
    endNow: function () {
      this.endTime = date.format(new Date(), 'hh:mm A')
    },
    editProject: function (id) {
      let idx = this.projectsIdx.indexOf(id)
      if (idx === -1) {
        alert('Unknown project ID!')
        return false
      }
      let item = this.projects[idx]
      this.projectProjectId = item.ID
      this.projectCognitoId = item.cognito_id
      this.projectCode = item.code
      this.projectTitle = item.title
      this.projectDescription = item.description
      this.toggleProjectModal()
    },
    editJob: function (id) {
      let idx = this.jobsIdx.indexOf(id)
      if (idx === -1) {
        alert('Unknown job ID!')
        return false
      }
      let item = this.jobs[idx]
      this.jobJobId = item.ID
      this.jobProjectId = item.project_id
      this.jobAssigneeCognitoId = item.cognitoAssigneeId
      this.jobCreatorCognitoId = item.cognitoCreatorId
      this.jobTitle = item.title
      this.jobDescription = item.description
      this.jobResourceA = item.resource_a
      this.jobResourceB = item.resource_b
      this.jobAssignee = item.assignee
      this.jobStatusId = item.status_id
      this.toggleJobModal()
    },
    editActivity: function (id) {
      let idx = this.activityIdx.indexOf(id)
      if (idx === -1) {
        alert('Unknown activity ID!')
        return false
      }
      let item = this.activity[idx]
      this.activityActivityId = item.ID
      this.activityCognitoId = item.cognito_id
      this.activityProjectId = item.project_id
      this.activityProjectCode = item.projectCode
      this.activityJobId = item.job_id
      this.activityJobTitle = item.jobTitle
      this.description = item.description
      this.activityType = item.type_id
      this.startDate = this.dateFn(item.datetime_start)
      this.startTime = this.timeFn(item.datetime_start)
      this.endTime = this.timeFn(item.datetime_end)
      this.toggleActivityModal()
    },
    stopActivity: function (activityId) {
      const self = this
      let idx = this.activityIdx.indexOf(activityId)
      if (idx === -1) {
        alert('Unknown activity ID!')
        return false
      }
      let item = this.activity[idx]
      item.ts_end = new Date().getTime()
      item.datetime_end = `${new Date()}`
      saveActivity(self, item, (err) => {
        handleError(err)
        self.getActivity(activityId)
      })
    },
    resume: function (id) {
      const self = this
      if (!id) {
        alert('Missing activity ID!')
        return false
      }
      const reqResume = function () {
        const startDate = self.getToday()
        const startTime = date.format(new Date(), 'hh:mm A')
        const start = new Date(`${startDate} ${parseTime(startTime)}`)
        const ts_start = new Date(start).getTime()
        const payload = {
          ID: id,
          ts_start: ts_start,
          datetime_start: `${start}`,
          day_num: moment(ts_start).dayOfYear(),
          week_num: moment(ts_start).isoWeek()
        }
        // console.log(payload)
        return postService(self, payload, 'resume')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            self.isWorking = false
            self.getActivity(response.data)
            // self.activity.push(response.data)
            // self.activityIdx.push(response.data.ID)
          })
      }
      reqResume()
    },
    addActivity: function (evt) {
      if (evt) {
        evt.preventDefault()
      }
      // https://stackoverflow.com/questions/141348/how-to-parse-a-time-into-a-date-object-from-user-input-in-javascript
      const self = this
      if (!self.activityJobId) {
        alert('Missing job ID!')
        return false
      }
      if (!self.activityType) {
        alert('Missing activity type!')
        return false
      }
      if (!self.startDate) {
        alert('Missing start date!')
        return false
      }
      if (!self.startTime) {
        alert('Missing start time!')
        return false
      }
      /*      const parseTime = function (t) {
              if (!t) {
                return ''
              }
              var time = t.match(/(\d+)(?::(\d\d))?\s*(pm|am)?/i)
              return `${parseInt(time[1])}:${parseInt(time[2]) || 0}${time[3] ? ' '+time[3] : ''}`
            }*/
      const start = new Date(`${self.startDate} ${parseTime(self.startTime)}`)
      const end = self.endTime ? new Date(`${self.startDate} ${parseTime(self.endTime)}`) : ''
      const ts_start = start ? new Date(start).getTime() : null
      const ts_end = end ? new Date(end).getTime() : null
      let idx = self.jobsIdx.indexOf(self.activityJobId)
      if (idx === -1) {
        return alert('Could not find the job ID!')
      }
      if (self.jobs[idx].status_id !== 10 && self.jobs[idx].status_id !== 20) {
        return alert('The job you chose is not active!')
      }
      self.isWorking = true
      const payload = {
        project_id: self.jobs[idx].project_id,
        job_id: self.activityJobId,
        type_id: self.activityType,
        datetime_start: `${start}`,
        datetime_end: `${end}`,
        day_num: moment(ts_start).dayOfYear(),
        week_num: moment(ts_start).isoWeek(),
        ts_start: ts_start,
        ts_end: ts_end,
        description: self.description || ''
      }
      if (self.activityActivityId) {
        payload['ID'] = self.activityActivityId
      }
      saveActivity(self, payload, (err, id) => {
        handleError(err)
        self.isWorking = false
        self.getActivity(self.activityActivityId || id)
        self.emptyActivityItem()
        self.toggleActivityModal()
      })
      /*      const reqAddActivity = function () {
              return postService(payload, 'saveActivity')
                .catch((err) => {
                  handleError(err)
                })
                .then((response) => {
                  self.isWorking = false
                  if (response && response.data && response.data.msg) {
                    alert(response.data.msg)
                    return false
                  }
                  self.getActivity(self.activityActivityId || response.data)
                  self.emptyActivityItem()
                  if (!evt) {
                    self.toggleActivityModal()
                  }
                })
            }
            reqAddActivity() */
    },
    getActivity: function (activityActivityId) {
      const self = this
      self.isLoadingActivities = true
      const reqGetActivity = function () {
        const payload = { ID: activityActivityId, offset: self.activityOffset }
        return postService(self, payload, 'activity')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            if (response.data.length === 0) {
              self.lastActivityEntry = true
            }
            response.data.map(x => {
              let idx = self.activityIdx.indexOf(x.ID)
              if (idx === -1) {
                self.activity.push(x)
                self.activityIdx.push(x.ID)
              } else {
                self.activity[idx] = x
              }
            })
            self.isLoadingActivities = false
          })
      }
      reqGetActivity()
    },
    delActivity: function (id) {
      const self = this
      if (!confirm('Are you sure you want to delete?')) {
        return false
      }
      // self.toggleActivityModal()
      const reqDelActivity = function () {
        return postService(self, { id: id }, 'deleteActivity')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            let idx = self.activityIdx.indexOf(id)
            if (idx !== -1) {
              self.activity.splice(idx, 1)
              self.activityIdx.splice(idx, 1)
            }
            // console.log('done', response)
            self.emptyActivityItem()
          })
      }
      reqDelActivity()
    },
    delJob: function (id) {
      const self = this
      if (!confirm('Are you sure you want to delete?')) {
        return false
      }
      const reqDelJob = function () {
        return postService(self, { id: id }, 'deleteJob')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            let idx = self.jobsIdx.indexOf(id)
            if (idx !== -1) {
              self.jobs.splice(idx, 1)
              self.jobsIdx.splice(idx, 1)
            }
            self.emptyJobItem()
          })
      }
      reqDelJob()
    },
    delProject: function (id) {
      const self = this
      if (!confirm('Are you sure you want to delete?')) {
        return false
      }
      self.toggleProjectModal()
      const reqDelProject = function () {
        return postService(self, { id: id }, 'deleteProject')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            let idx = self.projectsIdx.indexOf(id)
            if (idx !== -1) {
              self.projects.splice(idx, 1)
              self.projectsIdx.splice(idx, 1)
            }
            // console.log('done', response)
            self.emptyProjectItem()
          })
      }
      reqDelProject()
    },
    getProject: function (id) {
      const self = this
      const reqGetProject = function () {
        return postService(self, {}, 'project')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            response.data.map(x => {
              let idx = self.projectsIdx.indexOf(x.ID)
              if (idx === -1) {
                self.projects.push(x)
                self.projectsIdx.push(x.ID)
              } else {
                // self.projects.splice(idx, 1, x)
                self.projects[idx] = x
              }
            })

            // console.log('done', self.types, self.projects)
          })
      }
      reqGetProject()
    },
    getMeta: function (id) {
      const self = this
      const reqGetMeta = function () {
        return postService(self, {}, 'meta')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            self.types = response.data.types
            self.userLevel = response.data.user[0].level
            /*            response.data.jobs.map(x => {
                          let idx = self.activeJobsIdx.indexOf(x.ID)
                          if (idx === -1) {
                            self.activeJobs.push(x)
                            self.activeJobsIdx.push(x.ID)
                          } else {
                            self.activeJobs.splice(idx, 1, x)
                          }
                        }) */
          })
      }
      reqGetMeta()
    }
  }
}

</script>
<style lang="css">
table,
tr,
td {
  padding: 0;
}

.form-group {}

.form-checkbox {
  font-size: 0.7rem;
}

.item-title {
  font-size: 1rem;
  font-weight: 600;
}

.col-title {
  font-size: 1.3rem !important;
  line-height: 1.3rem !important;
  letter-spacing: -0.05rem;
  font-style: normal;
  font-weight: 800 !important;
  color: #333740 !important;
  /*margin-bottom: .5em;*/
  margin-top: 0;
}

.table-ifh {
  width: 100%;
  border-spacing: 0 13px;
}

.table-ifh-row {
  height: 70px;
  background-color: #FFFFFF;
  /*cursor: pointer;*/
  background-clip: padding-box;
  border-radius: 5px;
}

.table-ifh-cell {
  font-size: 0.7rem;
  padding: 4px 6px;
  vertical-align: top;
}

.table-ifh-last-cell {
  min-width: 90px;
  width: 90px;
  vertical-align: text-top;
  text-align: center;
  padding-top: 0.3rem;
}

.cell-content-title {
  font-weight: 600;
}

.cell-content-block {}

.content-area {
  padding: 4px;
  font-size: .8rem;
  border: 1px solid #bcc3ce;
  min-height: 80px;
}

.label-status {
  font-size: 0.5rem;
}

.icon-small {
  font-size: .5rem;
  margin-bottom: 0.1rem;
}

.person-label {
  font-weight: 600;
  color: #5755D9;
}

.resource-label {
  color: #5755D9;
}

.resource-label .icon-small {
  font-size: .7rem;
}

</style>
