<template>
  <div>
    <SiteNav />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-10 col-8 col-mx-auto">
          <div class="section-spacer"></div>
          <div v-if="!signedUp" class="columns">
            <div class="column col-lg-12 col-6 p-centered">
              <p>Fill out this form to create an account.</p>
              <p>Username and password are case sensitive. The username must have between 5 to 12 characters.</p>
              <p>The password must be at least 8 characters long and include upper and lowercase letters, numbers and symbols.</p>
              <div class="text-center">
                <div class="label label-error mb-2" v-if="error != null">{{ error }}</div>
                <form @submit.prevent="signUp">
                  <div class="form-group">
                    <input id="username" type="text" class="form-input" v-model="username" placeholder="username" required pattern="[a-zA-Z0-9]{5,12}$">
                  </div>
                  <div class="form-group">
                    <input id="email" type="text" class="form-input" v-model="email" placeholder="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,14}$">
                  </div>
                  <div class="form-group">
                    <input id="password" type="password" class="form-input" placeholder="password" v-model="pass" required>
                  </div>
                  <!-- <div class="form-group">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="mailing">
                      <i class="form-icon"></i> Send me very occasional email updates
                    </label>
                  </div> -->
                  <div class="center-align">
                    <button class="btn btn-sm col-12" :class="{loading:isWorking}">Sign up</button>
                    <div class="mt-2"></div>
                    <p>
                      <small>
                        <router-link :to="{name: 'Login'}">Log in here</router-link>
                      </small>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="column col-12 p-centered">
              <h5>{{message}}</h5>
            </div>
          </div>
          <div class="section-spacer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SiteNav from '@/components/SiteNav.vue'
export default {
  name: 'Register',
  title: function () {
    return 'Intel for hire :: Sign up'
  },
  components: {
    SiteNav
  },
  data: function () {
    return {
      username: '',
      pass: '',
      email: '',
      error: null,
      loggedIn: false,
      isWorking: false,
      signedUp: false,
      message: '',
      mailing: 1
    }
  },
  beforeCreate: function () {
    document.body.className = 'public-zone'
  },
  methods: {
    signUp: function () {
      const self = this
      this.isWorking = true
      this.$cognito.signup(this.username, this.email, this.pass, this.mailing, (err) => {
        if (err) {
          self.$gtag.event('signup_error', {
            event_category: 'user',
            event_label: self.username
          })
          self.error = err.message
          self.isWorking = false
          return false
        }
        this.message = 'Good. Please check your email to confirm your registration.'
        this.signedUp = true
        self.$gtag.event('signup_success', {
          event_category: 'user',
          event_label: self.username
        })
        // this.$router.replace({ path: '/confirm' })
      })
    }
  }
}

</script>
