<template>
  <div>
    <SiteNav :username="username" />
    <div class="container">
      <div class="columns">
        <div class="column col-lg-8 col-8 col-mx-auto text-center">
          <h4>
            Projects
          </h4>
          <div class="section-spacer"></div>
          <form @submit.prevent="addJob">
            <div class="form-group">
              <!-- select project Id -->
              <div class="form-inline mb-1 mr-1">
                <select class="form-select" id="projectId" v-model="projectId" @change="getProjectData">
                  <option value="">Choose a project</option>
                  <option v-for="project in projects" v-bind:value="project.ID" v-bind:key="project.ID">
                    {{project.code}}
                  </option>
                </select>
              </div>
            </div>
          </form>
          <div class="section-spacer"></div>
          <canvas id="chartEl" width="400" height="200"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import SiteNav from '@/components/SiteNav.vue'
import { postService } from '@/services/newpost'
import handleError from '@/services/handle-error'
import moment from 'moment'
export default {
  name: 'Metrics',
  title: function () {
    return 'Intel for hire :: Metrics'
  },
  components: {
    SiteNav
  },
  data: function () {
    return {
      chartData: {
        title: '',
        labels: [],
        datasets: [],
        datasetsIdx: []
      },
      // totalHours: 0,
      totalByProject: {},
      weekNum: moment().isoWeek(),
      // chartEl: null,
      isWorking: false,
      projects: [],
      projectsIdx: [],
      projectId: ''
    }
  },
  created: function () {
    const self = this
    this.user = this.$cognito.getUser()
    this.userCognitoId = this.$cognito.getUserId()
    this.username = this.user ? this.user.username : ''
  },
  mounted: function () {
    this.getProjects()
  },
  methods: {
    getProjects: function (id) {
      const self = this
      const reqGetProject = function () {
        return postService(self, {}, 'project')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            response.data.map(x => {
              let idx = self.projectsIdx.indexOf(x.ID)
              if (idx === -1) {
                self.projects.push(x)
                self.projectsIdx.push(x.ID)
              } else {
                self.projects[idx] = x
              }
            })
          })
      }
      reqGetProject()
    },
    drawChart: function () {
      const self = this
      let ctx = document.getElementById('chartEl').getContext('2d')
      let bgColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ]
      let borderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ]
      let mychart = this.chartEl = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: self.chartData.labels,
          datasets: [], // self.chartData.datasets
          datasetsIdx: []
          /*[{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: bgColors,
            borderColor: borderColors,
            borderWidth: 1
          }]*/
        },
        options: {
          interaction: {
            intersect: false,
            mode: 'point',
          },
          plugins: {
            title: {
              display: true,
              text: self.chartData.title
            },
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: true,
              title: {
                display: true,
                text: 'Work hours'
              }
            }
          }
        }
      })
    },
    /*    setCurrentWeek: function () {
          this.weekNum = moment().isoWeek()
          this.getWeek()
        },
        changeWeek: function (chg) {
          if (chg > 0) {
            this.weekNum = (this.weekNum + chg <= 52) ? this.weekNum + chg : this.weekNum
          }
          if (chg < 0) {
            this.weekNum = (this.weekNum + chg >= 0) ? this.weekNum + chg : this.weekNum
          }
          this.getWeek()
        }, */
    getProjectData: function () {
      const self = this
      self.isWorking = true
      const payload = {
        projectId: this.projectId
      }
      const getHours = function (minutes) {
        return Math.round(minutes / 60 * 10) / 10
      }
      const getRangeDates = function (start, end) {
        for (var dates = [], i = start; i <= end; i++) {
          if (i) {
            dates.push(moment().dayOfYear(i).format('ddd M/D'))
          }
        }
        // console.log(dates)
        return dates
      }
      const emptyChart = function (chart) {
        let num = chart.data.datasets.length
        for (let i = 0; i < num; i++) {
          chart.data.datasets.pop()
          chart.update('hide')
        }
      }
      const reqProject = function () {
        return postService(self, payload, 'metricsProject')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            if (!response) {
              return false
            }
            /*            let bgColors = [ // https://trycolors.com/palette/baa803/
                          '#FFAAAA', '#FF8080', '#FF0000', '#800000', '#550000', '#803B00', '#AA4F00', '#FF7700', '#FFA455', '#FFBB80', '#FFF9AA', '#FFF680', '#FFED00', '#807700', '#554F00', '#003C00', '#005B00', '#00B500', '#80DA80', '#AAE6AA', '#AAF9FF', '#80F6FF', '#00EDFF', '#007780', '#004F55', '#001839', '#002456', '#0047AB', '#80A3D5', '#AAC2E3', '#BF91D5', '#AA6DC7', '#8024AB', '#551872', '#401256', '#550039', '#800056', '#FF00AB', '#FF80D5', '#FFAAE3'
                        ] */
            let bgColors = [ // https://trycolors.com/palette/baa8zt/
              '#FF5555',
              '#FF9233',
              '#FFF355',
              '#99DA33',
              '#55CE55',
              '#33DA99',
              '#55F3FF',
              '#33AEDD',
              '#5584C7',
              '#994FBC',
              '#FF55C7',
              '#B65855',
              '#997B33',
              '#555555'
            ]
            let totalMinutes = 0
            self.totalByProject = {}
            let dataset = {}
            let dates = {}
            let min = 999
            let max = 0
            // let labels = getISOWeekDates(self.weekNum)
            response.data.map(type => {
              dataset[type.title] = dataset[type.title] ? dataset[type.title] : {}
              totalMinutes += type.minutes
              self.totalByProject[type.title] = self.totalByProject[type.title] ? self.totalByProject[type.title] + type.minutes : type.minutes
              dataset[type.title][type.date] = getHours(type.minutes)
              dates[type.date] = 1
              min = min > type.day_num ? type.day_num : min
              max = max < type.day_num ? type.day_num : max
            })
            const data = {}
            let title = `Allocation of ${getHours(totalMinutes)} work hours for project`
            let labels = getRangeDates(min, max) // Object.keys(dates)
            labels.map((label, i) => {
              self.chartData.labels[i] = label
            })
            self.chartData.title = title
            if (self.chartEl) {
              self.chartEl.options.plugins.title.text = title
              emptyChart(self.chartEl)
            } else {
              self.drawChart()
            }
            Object.keys(self.totalByProject).map((type, z) => {
              data[type] = new Array(labels.length)
              self.chartData.labels.map((d, i) => {
                data[type][i] = dataset[type][d] || null
              })
              let hours = getHours(self.totalByProject[type])
              let obj = {
                label: `${type} (${getHours(self.totalByProject[type])} hrs)`,
                data: data[type],
                backgroundColor: bgColors[z]
              }
              self.chartEl.data.datasets.push(obj)
              self.chartEl.data.datasetsIdx.push(type)
            })
            try {
              self.chartEl.update()
            } catch (e) {
              console.error(e)
            }
            self.isWorking = false
          })
      }
      reqProject()
    }
  }
}

</script>
