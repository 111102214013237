import { postService } from '@/services/newpost'
import parseGoogleQuery from '@/services/parse-google-query'

function getFindings (self, options, callback) {
  const reqGetFindings = function () {
    return postService(self, options, 'findings')
      .catch((err) => {
        callback(err)
      })
      .then((response) => {
        if (!response) {
          return callback(new Error('No response!'))
        }
        let currentFindingsIds = []
        // self.queryStrings = []
        response.data.map(x => {
          if (x.type_id === 30 && x.querystring) {
            x.querystring = parseGoogleQuery(x.querystring)
            // self.queryStrings.push(x.querystring)
          }
          let idx = self.findingsIdx.indexOf(x.ID)
          if (idx === -1) {
            self.findings.push(x)
            self.findingsIdx.push(x.ID)
          } else {
            self.findings[idx] = x
          }
          currentFindingsIds.push(x.ID)
          self.latestBookmarkId = self.latestBookmarkId < x.ID && !options.id ? x.ID : self.latestBookmarkId
        })
        if (!options.ID) {
          let deleteTheseFindings = []
          self.findings.map(j => {
            if (currentFindingsIds.indexOf(j.ID) === -1) {
              deleteTheseFindings.push(j.ID)
            }
          })
          deleteTheseFindings.map(id => {
            let idx = self.findingsIdx.indexOf(id)
            self.findings.splice(idx, 1)
            self.findingsIdx.splice(idx, 1)
          })
        }
        callback(null)
      })
  }
  reqGetFindings()
}

export { getFindings }
