<template>
  <div class="m-2">
    <!--     <div class="container">
      <div class="columns">
        <div class="column col-lg-12 col-mx-auto"> -->
    <header class="navbar">
      <section class="navbar-header">
        <a href="/" class="navbar-brand">intel for hire</a>
      </section>
      <section v-if="username" class="navbar-section">
        <span class="username"><i class="icon icon-people icon-small"></i> {{username}}</span>
        <router-link to="/feed" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" role="link" class="btn btn-sm ml-2">Activity</button>
        </router-link>
        <router-link to="/metrics" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" role="link" class="btn btn-sm ml-2">My week</button>
        </router-link>
        <router-link to="/projects" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" role="link" class="btn btn-sm ml-2">Projects</button>
        </router-link>
        <router-link to="/findings" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" role="link" class="btn btn-sm ml-2">Findings</button>
        </router-link>
        <router-link to="/logout" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" role="link" class="btn btn-sm ml-2">Log out</button>
        </router-link>
      </section>
    </header>
    <!--         </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'SiteNav',
  props: {
    username: {
      type: String
    },
  },
  data: function () {
    return {}
  }
}

</script>
<style scoped>
.navbar {
  margin-top: 0;
}

.username {
  font-size: 0.7rem;
  font-weight: 600;
  color: #5755D9;
}

</style>
