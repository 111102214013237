import * as axios from 'axios'

function postService (app, payload, path) {
  return new Promise(function (resolve, reject) {
    app.$cognito.getIdToken((err, result) => {
      if (err) {
        // console.log(err)
        return reject(err)
      } else {
        axios.defaults.headers.common.Authorization = result
        // payload.user = Cognito.getUser().username
        // payload.userId = Cognito.getUserId()
        // console.log(payload)
        // console.log(process.env, result, payload)
        const params = {
          method: 'post',
          url: process.env.VUE_APP_EXEC_API + '/' + path,
          data: payload
        }
        return axios(params)
          .then(x => {
            // console.log('new dash', x)
            resolve(x)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  })
}

export {
  postService
}
