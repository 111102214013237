import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag-next"
import Cognito from '@/cognito/cognito'
import config from '@/config'
import titleMixin from './services/titleMixin'

const app = createApp(App)

app.mixin(titleMixin)

app.config.globalProperties.$filters = {
  truncate(value, size) {
    if (!value) {
      return ''
    }
    value = value.toString()
    return (value.length <= size) ? value : (value.substr(0, size) + '...')
  }
}

app.use(VueGtag, {
  property: { id: 'UA-55345670-7' },
  enabled: (process.env.NODE_ENV !== 'development')
})

app.use(Cognito, config) // new Vue 3's app takes the object constructor, not an instance of it
app.use(router, app)
app.mount('#app')
export default app
