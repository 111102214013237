const extractDomain = require('extract-domain')
module.exports = (url) => {
  if (!url) {
    return ''
  }
  if (url.startsWith('javascript:')) {
    return '[bookmarklet]'
  }
  if (url.startsWith('http://localhost')) {
    return 'localhost'
  }
  const out = extractDomain(url)
  const regex = /^[a-z]{2,3}\.[a-z]{2}$/gi
  const match = out.match(regex)
  if (!match) {
    return out
  }
  const tmp = new URL(url)
  if (!tmp) {
    return out
  }
  return tmp.host.split('.').slice(-3).join('.')
}
