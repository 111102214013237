<template>
  <div class="m-2">
    <div class="form-group">
      <div v-if="jobs.length">
        <label class="form-label" for="jobId">Job</label>
        <select v-model="jobId" class="form-select" required>
          <option disabled value="">choose a job</option>
          <option v-bind:value="job.ID" v-bind:key="job.ID" v-for="job of jobItems">
            {{job.code}}-{{job.ID}} / {{job.title}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label" for="title">Title</label>
      <input id="title" type="text" class="form-input" v-model.trim="title" required>
    </div>
    <!--       <div class="form-group">
        <label class="form-label" for="url" style="display: inline-block;">url</label>
        <span v-if="candidates.length">
          <span class="ml-2">/ </span>
          <a class="b-version" :class="{'selected-b-version': !bookmarkId}" v-on:click="selb()">new bookmark</a>
          <span class="ml-2 mr-2">/ bookmarked on:</span><br class="show-xs">
          <span v-bind:key="candidate.bookmarkId" v-for="candidate in candidates"><a class="mr-2 b-version" :class="{'selected-b-version': candidate.bookmarkId === bookmarkId}" v-on:click="selb(candidate.bookmarkId)">{{formatTs(candidate.createdAt)}}</a></span>
          <div class="mb-2"></div>
        </span>
        <input id="url" type="text" class="form-input" v-model="url" required>
      </div> -->
    <div class="form-group">
      <label class="form-label" for="notes">Notes</label>
      <textarea id="notes" class="form-input" v-model.trim="notes" rows="2"></textarea>
    </div>
    <div class="form-group">
      <label class="form-label" for="topics">Topics (separate with comma)</label>
      <TopicSelect :fieldId="topicFieldId" :initialTopics="topics" :topicOptions="topicOptions" v-on:topicSelectEmit="topicUpdate" />
    </div>
    <div class="divider text-center" data-content="Choose a type of finding"></div>
    <div class="text-center">
      <button class="btn btn-sm mr-2" v-on:click="typeId=50;saveBookmark()" :class="{loading:isAdding}">Event</button>
      <button class="btn btn-sm mr-2" v-on:click="typeId=10;saveBookmark()" :class="{loading:isAdding}">Source</button>
      <button class="btn btn-sm mr-2" v-on:click="typeId=20;saveBookmark()" :class="{loading:isAdding}">Company</button>
      <button class="btn btn-sm mr-2" v-on:click="typeId=40;saveBookmark()" :class="{loading:isAdding}">Content</button>
      <div class="d-inline-block mr-2">&middot;</div>
      <button class="btn btn-sm mr-2" v-on:click="typeId=30;saveBookmark()" :class="{loading:isAdding}">Search results</button>
      <div class="mt-2">
        <a v-on:click="closeWindow()">Cancel</a>
      </div>
    </div>
  </div>
</template>
<script>
const queryString = require('query-string')
import { sortMultiple } from '@/services/sort'
import TopicSelect from '@/components/TopicSelect.vue'
import { postService } from '@/services/newpost'
import extractDomain from '@/services/extract-domain'
import handleError from '@/services/handle-error'
import { getJobs } from '@/services/jobs/get'
import { getTopics } from '@/services/jobs/topics'
/*import config from '@/config'
import dayjs from 'dayjs'
import series from 'async-series'
import { s3put } from '@/services/s3/put'
import { getDashFromS3 } from '@/services/board/get'
import TopicSelect from '@/components/nested/TopicSelect.vue'
import handleError from '@/services/handle-error'
import { newBookmark } from '@/services/bookmark/new'
import { sortMultiple } from '@/services/lib/sort'
import { selectObjectContent } from '@/services/s3/select-object-content.js'
import shortHash from '@/services/lib/short-hash'
*/
const initData = {
  userId: null,
  username: null,
  loggedIn: false
}

export default {
  name: 'Bookmark',
  title: function () {
    return 'Intel for hire App :: Bookmark'
  },
  components: {
    TopicSelect
  },
  props: {},
  data: function () {
    return {
      ...initData,
      isAdding: false,
      originalTitle: '',
      title: '',
      url: '',
      notes: '',
      bookmarkId: '',
      topics: [],
      typeId: '',
      candidates: [],
      topicOptions: [],
      topicFieldId: 'topics',
      jobs: [],
      jobsIdx: [],
      activity: [],
      activityIdx: [],
      jobId: parseInt(localStorage.getItem('mostRecentJobId')) || null
    }
  },
  beforeCreate: function () {
    // document.body.className = 'auth-zone'
  },
  created: function () {
    var self = this
    this.$cognito.isAuth((err, isLogged) => {
      self.loggedIn = isLogged
      const user = self.$cognito.getUser()
      self.userId = self.$cognito.getUserId()
      self.username = user ? user.username.toLowerCase() : ''
      if (err) {
        return handleError(err)
      }
      self.title = this.$route.query.title
      self.originalTitle = this.$route.query.title
      self.url = self.cleanUrl(this.$route.query.bookmark)
    })
  },
  mounted: function () {
    const self = this
    getJobs(this, { username: this.username }, (err) => {
      handleError(err)
    })
    getTopics(self, self.jobId, (err, arr) => {
      handleError(err)
      self.topicOptions = arr
    })
  },
  watch: {
    jobId: function (newVal) {
      const self = this
      getTopics(self, newVal, (err, arr) => {
        handleError(err)
        self.topicOptions = arr
      })
    }
  },
  computed: {
    jobItems: function () {
      return this.jobs.slice()
        .filter(j => j.assignee === this.username)
        // 10 = pending, 20 = in progress, 30 = complete, 40 = canceled
        .filter(j => j.status_id === 10 || j.status_id === 20)
        .sort(sortMultiple('-ID'))
    }
  },
  methods: {
    cleanUrl: function (str) {
      return str ? str.split('#:~:text')[0] : ''
    },
    closeWindow: function (err) { // Firefox fix: window can't be closed when opened by the bookmarklet
      handleError(err)
      const win = window.open('', '_self', '')
      win.close()
    },
    formatTs: function (ts, format = 'DD-MMM-YYYY') {
      return ts ? dayjs(ts).format(format) : ''
    },
    topicUpdate: function (data) {
      this.topics = data
    },
    getJobs: function (argument) {
      // body...
    },
    saveBookmark: function () { // HERE SAVE DASH FILE INCLUDING NEW BOOKMARK
      const self = this
      let isEdit = false
      self.isAdding = true
      if (!self.url) {
        return false
      }
      const parsed = queryString.parseUrl(this.url)
      console.log(parsed)
      let idx = self.jobsIdx.indexOf(self.jobId)
      if (idx === -1) {
        handleError(new Error('Project ID not found!'))
      }
      let projectId = self.jobs[idx].project_id
      const payload = {
        title: this.title,
        url: this.url,
        notes: this.notes,
        domain: extractDomain(this.url),
        jobId: this.jobId,
        projectId: projectId,
        topics: this.topics.join(','),
        typeId: this.typeId, // this.type,
        baseUrl: parsed.url,
        querystring: this.typeId === 30 ? JSON.stringify(parsed.query) : ''
      }
      if (self.bookmarkId) {
        payload['ID'] = self.bookmarkId
      }
      const reqAddBookmark = function () {
        return postService(self, payload, 'saveBookmark')
          .catch((err) => {
            handleError(err)
          })
          .then((response) => {
            self.isAdding = false
            if (response && response.data && response.data.msg) {
              alert(response.data.msg)
              return false
            }
            localStorage.setItem('mostRecentBookmarkId', response.data)
            localStorage.setItem('mostRecentJobId', self.jobId)
            self.closeWindow()
          })
      }
      reqAddBookmark()
    }
  }
}

</script>
<style scoped>
a,
label {
  font-size: 0.7rem;
}

.form-label {
  margin: 0 0.1rem !important;
  line-height: 0.8rem;
}

.b-version {
  cursor: pointer;
}

.selected-b-version {
  font-weight: 900;
}

.chip a,
.router-link-exact-active a,
.router-link-active a {
  outline: none !important;
}

.menu-item {
  cursor: pointer;
  line-height: 17px;
  font-size: 14px;
  margin-top: 0 !important;
}

</style>
