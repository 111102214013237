function dynamicSort (property) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
    return result * sortOrder
  }
}

function sortMultiple () {
  const props = arguments
  return function (obj1, obj2) {
    let i = 0
    let result = 0
    const numberOfProperties = props.length
    // try getting a different result from 0 (equal)
    // as long as we have extra properties to compare
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2)
      i++
    }
    return result
  }
}

export { sortMultiple }
