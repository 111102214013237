<template>
  <div class="form-autocomplete">
    <div class="form-autocomplete-input form-input">
      <div class="chip" :class="{'highlighted': idx + 1 === backspaceHighlightIdx}" v-bind:key="t" v-for="(t, idx) of chips">
        {{t}}
        <a v-on:click="removeOption(t)" class="btn btn-clear" aria-label="Close" role="button"></a>
      </div>
      <input class="form-input" :ref="fieldId" :id="fieldId" type="text" v-model="topics" v-on:input="lookup" v-on:keyup="action" @keydown.enter.prevent="enter" @keydown.backspace.prevent="backspace" autocomplete="off" placeholder="add topic">
    </div>
    <ul class="menu" :class="{hide: suggestions.length === 0}">
      <li class="menu-item" v-bind:key="s" v-for="(s, idx) of suggestions">
        <a v-on:click="addTopic(s)" :class="{'menu-item-selected': idx === suggestionsFocusIdx}" @mouseover="setFocusIdx(idx)">
          <div class="tile tile-centered">
            <div class="tile-content">
              {{s}}
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import config from '@/config'
import topicMaker from '@/services/make-topic'
const find = function (availableOptions, str) {
  const found = []
  const lower = []
  const _str = str.toLowerCase()
  if (!availableOptions || !str) {
    return found
  }
  availableOptions.map((t, i) => {
    const _t = t.toLowerCase()
    lower.push(_t)
    if (_t.indexOf(_str) > -1 && found.indexOf(t) === -1) {
      found.push(availableOptions[i])
    }
  })
  if (found.length === 0 || lower.indexOf(_str) === -1) {
    found.push(`Add "${str}"`)
  }
  return found
}
export default {
  name: 'TopicSelect',
  props: {
    fieldId: {
      type: String,
      default: 'k-topics'
    },
    initialTopics: {
      type: Array,
      default: () => []
    },
    topicOptions: {
      type: Array
    }
  },
  data: function () {
    return {
      topics: '',
      selectedTopics: [],
      suggestions: [],
      suggestionsFocusIdx: 0,
      backspaceHighlightIdx: null
    }
  },
  mounted: function () {
    // console.log(this.initialTopics)
    this.initialTopics.map(this.addTopic)
  },
  computed: {
    chips: function () {
      // if (this.selectedTopics.length) {
      return this.selectedTopics.slice()
      // } else {
      //  return this.initialTopics.slice()
      // }
    },
    availableTopics: function () {
      return this.topicOptions.slice().filter(t => { return !t.startsWith(config.TOPIC_PREFIX) })
    }
  },
  watch: {
    initialTopics: function (newValue) {
      this.selectedTopics = JSON.parse(JSON.stringify(newValue))
    }
  },
  methods: {
    emitToParent: function (event) {
      this.$emit('topicSelectEmit', this.chips)
    },
    resetSuggestions: function () {
      this.suggestions = []
      this.suggestionsFocusIdx = 0
    },
    enter: function (data) {
      data.preventDefault()
      this.backspaceHighlightIdx = null
      let target = this.topics
      const suggestedOptionsLength = this.suggestions.length
      if (suggestedOptionsLength) {
        target = this.suggestions[this.suggestionsFocusIdx]
        this.suggestionsFocusIdx = 0
      }
      this.addTopic(target)
      this.focusTopic()
      this.resetSuggestions()
      return false
    },
    backspace: function (event) {
      if (this.topics) {
        this.topics = this.topics.slice(0, -1)
        if (this.topics) {
          this.suggestions[this.suggestionsFocusIdx] = `Add "${this.topics}"`
        } else {
          this.resetSuggestions()
        }
        return false
      }
      const len = this.selectedTopics.length
      if (!this.backspaceHighlightIdx && len) {
        this.backspaceHighlightIdx = len
        return false
      } else {
        this.backspaceHighlightIdx = null
      }
      this.selectedTopics.pop()
      if (!this.selectedTopics.length) {
        this.resetSuggestions()
      }
      this.emitToParent()
    },
    action: function (data) {
      if (!data || ['Escape', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].indexOf(data.key) === -1) {
        return false
      }
      this.backspaceHighlightIdx = null
      if (data.key === 'Escape') {
        if (this.topics === '' && this.suggestions.length === 0) {
          // this.closeFn()
          // maybe attempting to close dialog without saving changes
        }
        this.topics = ''
        this.resetSuggestions()
        return false
      }
      const suggestedOptionsLength = this.suggestions.length
      if ((data.key === 'ArrowDown' || data.key === 'ArrowRight') && suggestedOptionsLength) {
        if (this.suggestionsFocusIdx === suggestedOptionsLength - 1) {
          return false
        }
        this.suggestionsFocusIdx++
        // console.log(this.suggestionsFocusIdx)
        return false
      }
      if ((data.key === 'ArrowUp' || data.key === 'ArrowLeft') && suggestedOptionsLength) {
        if (this.suggestionsFocusIdx === 0) {
          return false
        }
        this.suggestionsFocusIdx--
        // console.log(this.suggestionsFocusIdx)
        return false
      }
    },
    lookup: function (evt) {
      const self = this
      this.backspaceHighlightIdx = null
      const data = evt.data
      const target = self.topics
      if (!data) {
        return false
      }
      if (data === ',') {
        self.addTopic(target)
        self.focusTopic()
        return false
      }
      self.suggestions = []
      find(self.availableTopics, target).map(t => {
        if (self.suggestions.indexOf(t) === -1 && self.selectedTopics.indexOf(t) === -1) {
          self.suggestions.push(t)
        }
      })
      self.suggestionsFocusIdx = 0
    },
    setFocusIdx: function (idx) {
      this.suggestionsFocusIdx = idx
    },
    addTopic: function (t) {
      if (!t) {
        return false
      }
      t = topicMaker(t.replace('Add ', ''))
      // .replace(/[^a-z0-9_-\s]/gi, '')
      // .trim()
      if (!t) {
        this.resetSuggestions()
        this.topics = ''
        return false
      }
      const idx = this.suggestions.indexOf(t)
      this.suggestions.splice(idx, 1)
      if (!this.selectedTopics.length) {
        this.selectedTopics = this.chips
      }
      const idx2 = this.selectedTopics.indexOf(t)
      if (idx2 === -1) {
        this.selectedTopics.push(t)
      }
      this.topics = ''
      this.emitToParent()
    },
    removeOption: function (t) {
      // console.log('exisiting', this.selectedTopics, 'removing', t)
      if (!this.selectedTopics.length) {
        this.selectedTopics = this.chips
      }
      const idx = this.selectedTopics.indexOf(t)
      if (idx > -1) {
        this.selectedTopics.splice(idx, 1)
        this.availableTopics.push(t)
        // this.availableTopicsLower.push(t.toLowerCase())
      }
      if (this.topics === '') {
        this.resetSuggestions()
      }
      this.focusTopic()
      this.emitToParent()
    },
    focusTopic: function () {
      this.$nextTick(() => {
        this.$refs[this.fieldId].focus()
      })
    }
  }
}

</script>
<style>
.menu.hide {
  display: none;
}

.menu .menu-item {
  margin-top: 0 !important;
}

.menu-item {
  float: left !important;
  font-size: .8rem !important;
}

.menu-item-selected {
  background: #f1f1fc;
  color: #5755d9 !important;
  font-size: .8rem !important;
}

.menu {
  max-width: 350px !important;
}

.highlighted {
  background-color: yellow;
}

</style>
