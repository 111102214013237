import { postService } from '@/services/newpost'

function getTopics (self, jobId, callback) {
  const reqGetTopics = function () {
    let options = {
      ID: jobId
    }
    return postService(self, options, 'topics')
      .catch((err) => {
        return callback(err)
      })
      .then((response) => {
        if (!response) {
          return callback(new Error('No response!'))
        }
        callback(null, response.data)
      })
  }
  reqGetTopics()
}

export { getTopics }
